import * as types from "../../types";

export const setCheckoutModalStatus = (status: boolean) => {
  return {
    type: types.SET_CHECKOUT_MODAL_STATUS,
    payload: status
  };
};

export const setRightSideBarStatus = (status: boolean) => {
  return {
    type: types.SET_RIGHT_SIDE_BAR_STATUS,
    payload: status
  };
};

export const setAlertModalStatus = (status: boolean) => {
  return {
    type: types.SET_ALERT_MODAL_STATUS,
    payload: status
  };
};

export const setLoadingModalStatus = (status: boolean) => {
  return {
    type: types.SET_LOADING_MODAL_STATUS,
    payload: status
  };
};

export const setAlertModalMsg = (data: {
  text: string;
  buttonText: string;
  onPress: () => void;
  heading: string;
}) => {
  return {
    type: types.SET_ALERT_MODAL_DATA,
    payload: data
  };
};
