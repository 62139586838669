import { FC } from "react";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import LandIcon from "../../../../assets/land.svg";
import MapIcon from "@mui/icons-material/Map";
import {
  setPropertyType,
  setSelectablePlots,
  setSelectedPlot,
  setZoomToElement,
} from "../../../../redux/actions/plotActions";
import { useNavigate } from "react-router-dom";
// from "../../../../redux/actions/plotActions";

const Panel = styled("div")(({ theme }) => ({
  border: "0.5px solid " + alpha(theme.palette.text.primary, 0.3),
  borderRadius: "60px",
  margin: "20px 40px",
  marginBottom: "70px",
  padding: "40px 80px",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",

  [theme.breakpoints.down("sm")]: {
    padding: "20px 10px",
    margin: "20px",
    marginBottom: "70px",
  },
}));

const ItemBox = styled("div")(({ theme }) => ({
  width: "250px",
  height: "250px",
  borderRadius: "60px",
  backgroundColor: alpha(theme.palette.primary.dark, 0.2),
  overflow: "hidden",
  margin: "20px",
}));

const ItemImage = styled("div")(({ theme }) => ({
  width: "100%",
  height: "75%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ItemDetail = styled("div")(({ theme }) => ({
  width: "100%",
  height: "25%",
  backgroundColor: alpha(theme.palette.background.paper, 0.3),
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  padding: "25px",
}));

const LandImage = styled("img")(({ theme }) => ({
  height: "60%",
}));

const LandName = styled("h2")(({ theme }) => ({
  fontSize: "10px",
  color: theme.palette.primary.dark,
}));

const LandCordinates = styled("h2")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.text.primary,
}));

const NoItemText = styled("h2")(({ theme }) => ({
  fontSize: "26px",
  color: theme.palette.text.primary,
  fontWeight: "600",
  margin: "auto",
  marginTop: "100px",
  marginBottom: "100px",
  textAlign: "center",
  textTransform: "uppercase",
}));

const PanelComponent: FC<{ selectedTab: number, userDetails: any }> = ({ selectedTab, userDetails }) => {
  const theme = useTheme();

  const favourablePlots = useSelector((state: any) => {
    return state.userReducer.favouratePlots;
  });

  const dipatch = useDispatch();
  const navigate = useNavigate();

  const handleClickMyland = (item) => {
    let temp = item.split(",");
    dipatch(setPropertyType("showLand"));
    dipatch(setSelectedPlot({ top: temp[0], left: temp[1] }));
    dipatch(setZoomToElement(true));
    navigate("/home");
  };

  return (
    <Panel>
      {selectedTab === 0 && userDetails?.plot.length ? (
        userDetails?.plot.map((item: any) => {
          return (
            <ItemBox>
              <ItemImage>
                <LandImage src={LandIcon} />
              </ItemImage>
              <ItemDetail>
                <div>
                  <LandName>THE BAZAAR</LandName>
                  <LandCordinates>LAND ({item})</LandCordinates>
                </div>
                <MapIcon
                  sx={{ color: theme.palette.primary.dark , cursor:'pointer' }}
                  onClick={() => handleClickMyland(item)}
                />
              </ItemDetail>
            </ItemBox>
          );
        })
      ) : selectedTab === 0 && !userDetails?.plot.length ? (
        <NoItemText>No item to display</NoItemText>
      ) : null}

      {selectedTab === 1 && favourablePlots.length ? (
        favourablePlots.map((item: any) => {
          return (
            <ItemBox>
              <ItemImage>
                <LandImage src={LandIcon} />
              </ItemImage>
              <ItemDetail>
                <div>
                  <LandName>THE BAZAAR</LandName>
                  <LandCordinates>LAND ({item})</LandCordinates>
                </div>
                <MapIcon sx={{ color: theme.palette.primary.dark }} />
              </ItemDetail>
            </ItemBox>
          );
        })
      ) : selectedTab === 1 && !favourablePlots.length ? (
        <NoItemText>No item to display</NoItemText>
      ) : null}
    </Panel>
  );
};

export default PanelComponent;
