import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import ItemDetails from "./components/ItemDetails";
import CardDetails from "./components/CardDetails";
import { useMediaQuery, useTheme } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";

const MainContainer = styled("div")(({ theme }) => ({
  background: theme.palette.primary.main,
  height: "100vh",
}));

const BackDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  padding: "20px 30px",

  [theme.breakpoints.down("sm")]: {
    padding: "20px 10px",
  },
}));

const BackText = styled("h5")(({ theme }) => ({
  fontSize: "19px",
  lineHeight: "15px",
  textTransform: "uppercase",
  fontWeight: "500",
}));

const ChildContainer = styled("div")<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    height: "calc(100vh - 70px)",
  })
);

const Divider = styled("div")(({ theme }) => ({
  height: "calc(100vh - 140px)",
  border: "1px solid #6A5F6C",
  marginRight: "5%",
  marginTop: "40px",
  marginBottom: "25px",
  opacity: "0.2",
}));

const Checkout: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeTab, setActiveTab] = useState(0);

  const changeMobileTab = () => {
    if (activeTab === 0) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  };

  const navigate = useNavigate();
  const handleBack = () => {
    if (isMobile && activeTab === 1) {
      setActiveTab(0);
    } else {
      navigate("/");
    }
  };

  return (
    <MainContainer>
      <BackDiv onClick={handleBack}>
        <KeyboardArrowLeftIcon style={{ height: "30px", width: "30px" }} />
        <BackText>Back</BackText>
      </BackDiv>
      <ChildContainer isMobile={isMobile}>
        <ItemDetails
          isMobile={isMobile}
          acitveTab={activeTab}
          changeMobileTab={changeMobileTab}
        />
        {!isMobile && <Divider />}
        <CardDetails
          isMobile={isMobile}
          acitveTabForMobile={activeTab}
          changeMobileTab={changeMobileTab}
        />
      </ChildContainer>
    </MainContainer>
  );
};

export default Checkout;
