import { FC, useState } from "react";
import LangImg from "../../assets/land.svg";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatCurrency, getPlotPrice, getPlotZone } from "../../utils";
const MainContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "400px",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "30px",
  borderRadius: "20px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",

  [theme.breakpoints.down("md")]: {
    width: "300px"
  }
}));
const PriceText = styled("h4")(({ theme }) => ({
  fontSize: "15px",
  paddingLeft: "30px",
  fontWeight: "600",
  textTransform: "uppercase",
  color: theme.palette.primary.dark
}));

const CartPopUpContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "400px",
  opacity: "0.8",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "30px",
  borderRadius: "5px",
  position: "absolute",
  top: "-290px",
  right: "115px",
  // transform: "translate(-50%, -50%)",
  zIndex: "1000",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    right: "0px",
    top: "-300px",
    padding: "20px"
  }
}));

const Heading = styled("div")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "700",
  color: theme.palette.background.paper,
  textAlign: "center",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    fontSize: "18px"
  }
}));

const SubHeading = styled("div")(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.background.paper,
  textAlign: "center",
  marginBottom: "20px"
}));

const BorderButton = styled("div")(({ theme }) => ({
  width: "fit-content",
  borderRadius: "10px",
  border: "solid 1px " + theme.palette.primary.dark,
  color: theme.palette.primary.dark,
  margin: "15px auto",
  marginBottom: "0px",
  padding: "7px 50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "20px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.paper
  }
}));

const ViewCartButton = styled("div")(({ theme }) => ({
  width: "100%",
  borderRadius: "10px",
  border: "solid 1px " + theme.palette.primary.dark,
  color: theme.palette.primary.dark,
  margin: "auto",
  padding: "7px 50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.paper
  }
}));
const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.background.itemBoxColor,
  width: "fit-content",
  padding: "10px",
  borderRadius: "20px",

  [theme.breakpoints.down("md")]: {
    padding: "15px"
  }
}));

const Image = styled("img")(({ theme }) => ({
  height: "50px",
  width: "50px"
}));

const AlertModal: FC = () => {
  const navigate = useNavigate();
  const modalData = useSelector((state: any) => {
    return state.modalReducer.alertModalData;
  });
  const cart = useSelector((state: any) => {
    return state.cartReducer.plots;
  });

  if (modalData.text === "Item Added to Cart.") {
    return (
      <motion.div animate={{ y: 365 }} transition={{ delay: 0.25 }}>
        <CartPopUpContainer>
          <div
            style={{
              fontSize: "13px",
              opacity: "0.8",
              color: "white",
              position: "absolute",
              top: "20px",
              left: "20px",
              gap: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <DoneIcon fontSize="small" />
            <span>Item added to your cart</span>
          </div>
          <div
            style={{
              color: "white",
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer"
            }}
            onClick={modalData.onPress}
          >
            {" "}
            <CloseIcon />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "20px",
              padding: "30px 5px"
            }}
          >
            <ImageContainer>
              <Image src={LangImg} />
            </ImageContainer>
            <div
              style={{
                fontSize: "12px",
                width: "fit-content"
              }}
            >
              <p>Coordinates ({modalData.heading})</p>
              <p>Type ({getPlotZone(modalData.heading).type})</p>
              <p>Zone ({getPlotZone(modalData.heading).zone})</p>
            </div>
            <PriceText>
              {formatCurrency(getPlotPrice(modalData.heading))}$
            </PriceText>
          </div>
          <ViewCartButton
            onClick={() => {
              navigate("/checkout");
              modalData.onPress();
            }}
          >
            View My Cart ({cart.length})
          </ViewCartButton>
        </CartPopUpContainer>
      </motion.div>
    );
  }
  return (
    <MainContainer>
      <Heading>{modalData.heading}</Heading>
      <SubHeading>{modalData.text}</SubHeading>
      <BorderButton onClick={modalData.onPress}>
        {modalData.buttonText}
      </BorderButton>
    </MainContainer>
  );
};

export default AlertModal;
