import * as type from "../../types";

type ActionType = {
  type: string;
  payload: string;
};

type StateType = {
  plots: string[];
};

const initState: StateType = {
  plots: [],
};

const cartReducer = (
  state: StateType = initState,
  action: ActionType
): StateType => {
  switch (action.type) {
    case type.ADD_TO_CART: {
      let tempPlots = [...state.plots];
      if (!tempPlots.includes(action.payload)) {
        tempPlots.push(action.payload);
      }
      return {
        ...state,
        plots: tempPlots,
      };
    }
    case type.REMOVE_FROM_CART: {
      let tempPlots = [...state.plots];
      let index = state.plots.indexOf(action.payload);
      if (index > -1) {
        tempPlots.splice(index, 1);
      }
      return {
        ...state,
        plots: tempPlots,
      };
    }
    case type.EMPTY_CART: {
      return {
        ...state,
        plots: [],
      };
    }
  }
  return state;
};

export default cartReducer;
