import { styled, alpha } from '@mui/material/styles'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '80px',
  width: '95%',
  maxWidth: '450px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '15px',
  padding: '0px 7px',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: '0px 0px 10px' + theme.palette.primary.main,
  transition: 'opacity 0.3s linear',
}))

export const SubContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '50px',
  width: '95%',
  maxWidth: '450px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '15px',
  padding: '0px 7px',
  marginBottom: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflow: 'auto',
  justifyContent: 'center',
  transition: 'opacity 0.3s linear',
}))

export const Tab = styled('div')(({ theme }) => ({
  flex: 1,
  margin: '1%',
  height: '80%',
  borderRadius: '10px',
  backgroundColor:
    theme.palette.mode === 'light' ? '#fbfafa' : alpha('#fff', 0.2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const AreaButton = styled('div')<{ active: boolean }>(
  ({ theme, active }) => ({
    height: '30px',
    width: 'fit-content',
    borderRadius: '18px',
    border: 'solid 1px',
    margin: '10px 5px',
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '15px',
    cursor: 'pointer',
    opacity: '0.7',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
    fontWeight: '500',

    // '&:hover': {
    //   border: 'solid 1px ' + theme.palette.primary.dark,
    //   color: theme.palette.primary.dark,
    // },
  }),
)

export const Input = styled('input')(({ theme }) => ({
  fontSize: '10px',
  height: '20px',
  width: '100%',
  color: alpha(theme.palette.background.paper, 1),
  outline: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  fontWeight: '400',
  borderColor: alpha(theme.palette.background.paper, 0.5),
  borderBottom: 'solid 1px ' + alpha(theme.palette.background.paper, 0.5),
  padding: '0px 10px',
  paddingBottom: '10px',

  '::placeholder': {
    color: alpha(theme.palette.background.paper, 0.3),
  },
}))

export const VerticalDivider = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.3),
  width: '1px',
  height: '30px',
}))

export const BorderButton = styled('div')(({ theme }) => ({
  height: '30px',
  width: 'fit-content',
  borderRadius: '30px',
  border: 'solid 1px ' + theme.palette.primary.dark,
  color: theme.palette.primary.dark,
  margin: '25px auto',
  padding: '0px 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '10px',
  cursor: 'pointer',
  marginRight: '10px',

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.paper,
  },
}))

export const Heading = styled('div')(({ theme }) => ({
  fontSize: '15px',
  color: theme.palette.background.paper,
  textAlign: 'center',
  fontWeight: '500',
}))
