import { FC, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { ClickAwayListener, useTheme } from "@mui/material";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const MainContainer = styled("div")<{ active: boolean }>(
  ({ theme, active }) => ({
    borderRadius: "25px",
    width: "45px",
    height: "45px",
    display: active ? "flex" : "none",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "20px",
    right: "120px",
    zIndex: "10000",
    background: theme.palette.primary.main,
    cursor: "pointer",

    [theme.breakpoints.down("md")]: {
      height: "40px",
      width: "40px",
      top: "13px",
      right: "70px",
    },
  })
);

const ItemsCountContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  height: "25px",
  width: "25px",
  borderRadius: "100%",
  fontSize: "15px",
  lineHeight: "15px",
  fontWeight: "700",
  color: theme.palette.text.primary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  right: "-5px",
  bottom: "-5px",

  // [theme.breakpoints.down("md")]: {
  //   display: "none",
  // },
}));

const CartButton: FC = () => {
  const [active, setActive] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });

  const cart = useSelector((state: any) => {
    return state.cartReducer.plots;
  });

  useEffect(() => {
    if (authToken) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [authToken]);

  const getToolTip = () => {
    return (
      <div
        style={{
          fontSize: "19px",
          padding: "0px",
          cursor: "pointer",
          color: theme.palette.text.primary,
          width: "190px",
        }}
      >
        <div
          onClick={async () => {
            if (active) navigate(`/checkout`);
          }}
          style={{
            borderRadius: "5px",
            padding: "5px 10px",
            margin: "5px 0px",
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            lineHeight: "18px",
          }}
        >
          <ShoppingCartIcon
            style={{ height: "22px", width: "22px", marginRight: "10px" }}
          />
          Cart
        </div>
      </div>
    );
  };

  return (
    <Tooltip
      title={
        <ClickAwayListener
          onClickAway={() => {
            setShowTooltip(false);
          }}
        >
          {getToolTip()}
        </ClickAwayListener>
      }
      arrow={false}
      open={showTooltip}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: theme.palette.primary.main,
            borderRadius: "9px",
            "& .MuiTooltip-arrow": {
              color: alpha(theme.palette.text.primary, 1),
            },
            // boxShadow: "0px 0px 10px " + theme.palette.primary.dark,
            border: "solid 2px " + alpha(theme.palette.text.primary, 0.5),
            display: "block",
            marginRight: "10px",
          },
        },
      }}
    >
      <MainContainer
        active={authToken && cart.length > 0 ? true : false}
        onClick={() => {
          setShowTooltip(true);
        }}
      >
        <ShoppingCartIcon sx={{ height: "25px", width: "25px" }} />
        <ItemsCountContainer>{cart?.length}</ItemsCountContainer>
      </MainContainer>
    </Tooltip>
  );
};

export default CartButton;
