import { FC, useEffect, useState } from "react";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import axios from "axios";

import { Logo } from "../../assets/index";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import LoginForm from "./components/LoginForm";
import SignUpForm from "./components/SignUpForm";
import SocialLogin from "./components/SocialLogin";
import { BASE_URL } from "../../utils/api";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setLoadingModalStatus
} from "../../redux/actions/modalActions";
import { useMediaQuery } from "@mui/material";
import { UserCredential } from "firebase/auth";

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  flex: "1",
  [theme.breakpoints.down("sm")]: {
    height: "200px",
    width: "100vw",

    video: {
      borderBottomLeftRadius: "25px",
      borderBottomRightRadius: "25px"
    }
  }
}));

const AuthContainer = styled("div")(({ theme }) => ({
  height: "100%",
  background: theme.palette.primary.main,
  width: "700px",
  overflowY: "auto",
  zIndex: 10,
  paddingBottom: "50px",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    paddingBottom: "100px"
  },

  "::-webkit-scrollbar": {
    width: "7px"
  },

  "::-webkit-scrollbar-track": {},
  "::-webkit-scrollbar-thumb": {
    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
    borderRadius: "10px"
  }
}));

const LogoContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "20%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
}));

const LogoImage = styled("img")(({ theme }) => ({
  height: "38px"
}));

const LogoName = styled("div")(({ theme }) => ({
  fontSize: "25px",
  marginTop: "10px"
}));

export const FormHeading = styled("h1")(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: "24px",
  fontWeight: "600",
  textTransform: "uppercase",
  letterSpacing: "-0.46px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "22px"
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "20px"
  }
}));

export const FormSubHeading = styled("h1")(({ theme }) => ({
  color: "#665a68",
  fontSize: "16px",
  fontWeight: "600",
  marginTop: "10px",
  marginBottom: "40px",
  cursor: "pointer",
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    width: "calc(100% - 40px)",
    marginBottom: "10px"
  }
}));

export const ParentInput = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  margin: "0px auto",
  marginBottom: "0px",

  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}));

export const Input = styled("input")(({ theme }) => ({
  border: "none",
  borderBottom: "1px solid #665a68",
  background: "transparent",
  width: "100%",
  outline: "none",
  color: theme.palette.background.paper,
  fontSize: "20px",
  placeholderTextColor: "red",
  margin: "0px auto",
  marginTop: "25px",
  textIndent: "10px",
  padding: "8px 0px",

  "&::-webkit-input-placeholder": {
    color: "theme.palette.background.paper",
    opacity: "0.7",
    fontWeight: "500"
  },
  "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
  "-moz-user-select": "text !important" /* Firefox 2+ */,
  "-ms-user-select": "text !important" /* IE 10+ */,
  "user-select": "text !important" /* Standard syntax */,

  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 40px)"
  }
}));

export const ErrorText = styled("p")(({ theme }) => ({
  color: "#FF3131",
  fontSize: "12px",
  fontWeight: "600",
  opacity: 0.8,
  margin: "0px auto",
  marginTop: "10px",
  textAlign: "left",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 40px)"
  }
}));

const BackDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "20px 30px",

  [theme.breakpoints.down("sm")]: {
    padding: "20px 10px"
  }
}));

const BackText = styled("h5")(({ theme }) => ({
  fontSize: "19px",
  lineHeight: "15px",
  textTransform: "uppercase",
  fontWeight: "500"
}));

const AuthScreen: FC = () => {
  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });

  const useFirebaseDetails: UserCredential = useSelector((state: any) => {
    return state.userReducer.user;
  });

  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBackButton = () => {
    if (page === 2) {
      setPage(0);
    } else {
      navigate("/home");
    }
  };

  const createUser = async () => {
    dispatch(setLoadingModalStatus(true));
    axios
      .post(`${BASE_URL}/user/createUser`, {
        token: authToken,
        name: !!useFirebaseDetails?.user?.displayName
          ? useFirebaseDetails?.user?.displayName
          : "Exarta###User",
        bio: "Hey there! I am using Exarta.",
        walletAddress: "0x0000000000000000000000000000000000000000",
        websiteUrl: null,
        twitterHandle: null,
        instagramHandle: null,
        profileImgUrl: !!useFirebaseDetails?.user?.photoURL
          ? useFirebaseDetails?.user?.photoURL
          : null,
        isPrivate: true
      })
      .then((res) => {
        dispatch(setLoadingModalStatus(false));
        if (res.data.status) {
          navigate("/");
        }
      })
      .catch((err) => {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: err.message,
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
      });
  };

  useEffect(() => {
    const navigateUser = async () => {
      if (authToken) {
        dispatch(setLoadingModalStatus(true));
        const res = await axios.post(`${BASE_URL}/user/getUser`, {
          token: authToken
        });
        if (res.data.user == null) {
          createUser();
        } else {
          dispatch(setLoadingModalStatus(false));
          navigate("/");
        }
      }
    };
    navigateUser();
    // eslint-disable-next-line
  }, [authToken]);

  return (
    <MainContainer>
      {window.innerWidth > 600 && (
        <ImageContainer>
          <video
            width="100%"
            height="100%"
            autoPlay
            loop
            muted
            style={{ objectFit: "cover" }}
          >
            <source
              src={
                "https://d2btao7ncfyde3.cloudfront.net/exarta-site/Landsale%20Video%20Final.m4v"
              }
              type="video/mp4"
            />
          </video>
        </ImageContainer>
      )}
      <AuthContainer>
        <BackDiv>
          <KeyboardArrowLeftIcon
            style={{ height: "30px", width: "30px", cursor: "pointer" }}
            onClick={handleBackButton}
          />
          <BackText onClick={handleBackButton} style={{ cursor: "pointer" }}>
            Back
          </BackText>
        </BackDiv>

        <LogoContainer>
          <LogoImage src={Logo} />
          <LogoName style={{ fontFamily: "Astera" }}>EXARTA</LogoName>
        </LogoContainer>

        {page === 0 && <LoginForm setPage={setPage} />}
        {page === 1 && <SignUpForm setPage={setPage} />}
        {page === 2 && <ForgotPasswordForm setPage={setPage} />}

        {page === 0 && <SocialLogin />}
        {page === 1 && <SocialLogin />}
      </AuthContainer>
    </MainContainer>
  );
};

export default AuthScreen;
