/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { styled, alpha } from "@mui/material/styles";
import { useSelector } from "react-redux";

import AmenityCom from "../../../../assets/AmenityCom.svg";
import AmenityComDark from "../../../../assets/AmenityComDark.svg";
import AmenityRes from "../../../../assets/AmenityRes.svg";
import AmenityResDark from "../../../../assets/AmenityResDark.svg";
import AmenityCoordinate from "../../../../assets/AmenityCoordinate.svg";
import AmenityCoordinateDark from "../../../../assets/AmenityCoordinateDark.svg";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { useThemeSwitch } from "../../../../hooks/switchTheme";
import { formatCurrency, getPlotPrice } from "../../../../utils";

const Layout = styled("div")(({ theme }) => ({
  padding: "20px",
  width: "250px"
}));

const Container = styled("div")(({ theme }) => ({
  padding: "0px 14px",
  borderRadius: "5px",
  backgroundColor: alpha(theme.palette.background.paper, 0.3),
  color: theme.palette.text.primary,
  textAlign: "center",
  height: "35px",
  fontSize: "15px",
  lineHeight: "15px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const Index: FC<{
  xCoordinate: string;
  yCoordinate: string;
  propertyType: string;
  zone: string;
}> = ({ xCoordinate, yCoordinate, propertyType, zone }) => {
  const theme = useTheme();
  const [boughtPlotsKeys, setBoughtPlotsKeys] = useState<string[]>([]);

  const navigate = useNavigate();
  const [mode] = useThemeSwitch();

  const boughtPlots = useSelector((state: any) => {
    return state.plotReducer.boughtPlots;
  });
  // Get logged in user's uid
  const userId = useSelector((state: any) => {
    return state.userReducer.uid;
  });

  useEffect(() => {
    if (boughtPlots) {
      setBoughtPlotsKeys(Object.keys(boughtPlots));
    }
  }, [boughtPlots]);

  const getPlotDetails = () => {
    return (
      <Layout>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Container>
            <img
              src={mode === "dark" ? AmenityCoordinate : AmenityCoordinateDark}
              style={{
                height: "25px",
                width: "25px",
                fill: theme.palette.text.primary
              }}
            />
          </Container>
          <Container style={{ flex: 1, marginLeft: "8px" }}>
            {xCoordinate + "x," + yCoordinate + "y"}
          </Container>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <Container
            style={{
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px"
            }}
          >
            ZONE
          </Container>
          <Container
            style={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              padding: "0px",
              flex: 1
            }}
          >
            <Container
              style={{
                flex: 1,
                color:
                  zone === "yellow"
                    ? theme.palette.text.primary
                    : theme.palette.background.paper,
                backgroundColor:
                  zone === "red"
                    ? "#CA3C25"
                    : zone === "yellow"
                    ? "#F9DC5C"
                    : zone === "green"
                    ? "#519872"
                    : "#134074"
              }}
            >
              {zone.toUpperCase()}
            </Container>
          </Container>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <Container>
            <img
              src={
                propertyType.toLowerCase() === "commercial"
                  ? mode === "dark"
                    ? AmenityCom
                    : AmenityComDark
                  : mode === "dark"
                  ? AmenityRes
                  : AmenityResDark
              }
              style={{ height: "20px", width: "20px" }}
            />
          </Container>
          <Container style={{ flex: 1, marginLeft: "8px" }}>
            {formatCurrency(getPlotPrice(xCoordinate + "," + yCoordinate))} $
          </Container>
        </div>
      </Layout>
    );
  };

  const getUserPlotDetails = () => {
    return (
      <div
        style={{
          display: "flex",
          padding: "0px"
        }}
      >
        <img
          style={{
            height: "230px",
            width: "170px",
            objectFit: "cover",
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px"
          }}
          src={boughtPlots[xCoordinate + "," + yCoordinate]?.userDetails?.image}
        />
        <div
          style={{
            width: "fit-content",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
            padding: "20px 25px",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            style={{
              margin: "0px 15px",
              fontSize: "20px",
              lineHeight: "20px",
              fontWeight: "800",
              marginTop: "20px",
              color: theme.palette.text.primary
            }}
          >
            {userId ===
              boughtPlots[xCoordinate + "," + yCoordinate]?.userDetails?.uid ||
            !boughtPlots[xCoordinate + "," + yCoordinate].userDetails?.isPrivate
              ? boughtPlots[
                  xCoordinate + "," + yCoordinate
                ]?.userDetails?.name.replaceAll("###", " ")
              : "Exarta User"}
          </div>
          <div
            style={{
              fontSize: "15px",
              lineHeight: "15px",
              fontWeight: "500",
              marginTop: "7px",
              color: theme.palette.text.primary
            }}
          >
            {userId ===
              boughtPlots[xCoordinate + "," + yCoordinate]?.userDetails?.uid ||
            !boughtPlots[xCoordinate + "," + yCoordinate].userDetails?.isPrivate
              ? boughtPlots[xCoordinate + "," + yCoordinate]?.userDetails?.email
                  ?.length > 30
                ? boughtPlots[
                    xCoordinate + "," + yCoordinate
                  ]?.userDetails?.email?.slice(0, 27) + "..."
                : boughtPlots[xCoordinate + "," + yCoordinate]?.userDetails
                    ?.email
              : boughtPlots[xCoordinate + "," + yCoordinate]?.userDetails?.uid}
          </div>
          <div style={{ marginTop: "auto" }}>
            <Layout style={{ padding: "0px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Container>
                  <img
                    src={
                      mode === "dark"
                        ? AmenityCoordinate
                        : AmenityCoordinateDark
                    }
                    style={{
                      height: "25px",
                      width: "25px",
                      fill: theme.palette.text.primary
                    }}
                  />
                </Container>
                <Container style={{ flex: 1, marginLeft: "8px" }}>
                  {xCoordinate + "x," + yCoordinate + "y"}
                </Container>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px"
                }}
              >
                <Container>
                  <img
                    src={
                      propertyType.toLowerCase() === "commercial"
                        ? mode === "dark"
                          ? AmenityCom
                          : AmenityComDark
                        : mode === "dark"
                        ? AmenityRes
                        : AmenityResDark
                    }
                    style={{ height: "20px", width: "20px" }}
                  />
                </Container>
                <Container
                  style={{
                    flex: 1,
                    marginLeft: "8px",
                    color:
                      zone === "yellow"
                        ? theme.palette.text.primary
                        : theme.palette.background.paper,
                    backgroundColor:
                      zone === "red"
                        ? "#CA3C25"
                        : zone === "yellow"
                        ? "#F9DC5C"
                        : zone === "green"
                        ? "#519872"
                        : "#134074"
                  }}
                >
                  {zone.toUpperCase()}
                </Container>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px"
                }}
              >
                <Container
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%"
                  }}
                  onClick={() => {
                    navigate(
                      `/profile/${
                        boughtPlots[xCoordinate + "," + yCoordinate]
                          ?.userDetails?.uid
                      }`
                    );
                  }}
                >
                  <div style={{ flex: "1", textAlign: "left" }}>
                    VIEW PROFILE
                  </div>
                  <PersonIcon style={{ marginLeft: "auto" }} />
                </Container>
              </div>
            </Layout>
          </div>
        </div>
      </div>
    );
  };

  const getTooltip = () => {
    if (boughtPlotsKeys.includes(xCoordinate + "," + yCoordinate)) {
      return getUserPlotDetails();
    } else {
      return getPlotDetails();
    }
  };

  return getTooltip();
};

export default Index;
