import { UserCredential } from "firebase/auth";
import * as types from "../../types";

export const setFirebaseObj = (obj: any) => {
  return {
    type: types.SET_FIREBASE_OBJECT,
    payload: obj,
  };
};

export const changeAuthToken = (
  token: string | null,
  expiry: number | null
) => {
  return {
    type: types.SET_AUTH_TOKEN,
    payload: { authToken: token, expiryTime: expiry },
  };
};

export const clearAuth = () => {
  return {
    type: types.CLEAR_AUTH,
    payload: null,
  };
};

export const clearUser = () => {
  return {
    type: types.CLEAR_USER,
    payload: null,
  };
};

export const setUserFirebaseDetails = (user: UserCredential) => {
  return {
    type: types.SET_USER_FIREBASE_DETAILS,
    payload: user,
  };
};
