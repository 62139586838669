// @ts-ignore
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import * as Components from "./components";
import LandImg from "../../../../assets/land.svg";
import TeaLogo from "../../../../assets/tea.svg";
import BuildingLogo from "../../../../assets/building.svg";
import PetLogo from "../../../../assets/pet.svg";
import FoodLogo from "../../../../assets/food.svg";
import TrainLogo from "../../../../assets/train.svg";
import AmenitiesNames from "../Amenities/names.json";
import AmenitiesAxis from "../Amenities/objects.json";
import Cameras from "../Cameras/objects.json";
import ReactPlayer from "react-player";
import { useTheme } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setLoadingModalStatus,
  setRightSideBarStatus
} from "../../../../redux/actions/modalActions";
import axios from "axios";
import { BASE_URL } from "../../../../utils/api";
import { SET_USER_DETAILS } from "../../../../redux/types";
import ComPlotAxis from "../Categories/comPlotAxis.json";
import RedZoneAxis from "../Zones/redPlotsAxis.json";
import YellowZoneAxis from "../Zones/yellowPlotsAxis.json";
import GreenZoneAxis from "../Zones/greenPlotsAxis.json";
import LandImage from "../LandType/landType.json";
import { setSelectedPlot } from "../../../../redux/actions/plotActions";
import { styled } from "@mui/material/styles";
import { formatCurrency, getPlotPrice } from "../../../../utils";

export const CustomfavoriteIcon = styled(FavoriteIcon, {
  shouldForwardProp: (prop) => prop !== "active"
})<{
  active: boolean;
}>(({ theme, active }) => ({
  color: active ? "red" : theme.palette.primary.dark,
  "&:hover": {
    color: !active ? "red" : theme.palette.primary.dark,
    border: "1px solid " + !active ? "red" : theme.palette.primary.dark
  }
}));

const PlotDetailsDiv = styled("div")(({ theme }) => ({
  fontSize: "13px",
  fontWeight: "bold"
}));
const PlotDetailsText = styled("span")(({ theme }) => ({
  fontWeight: "normal",
  paddingLeft: "5px"
}));

const RightSideBar: FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLiked, setIsLiked] = useState(false);
  const [boughtPlotsKeys, setBoughtPlotsKeys] = useState<any>([]);
  const [landImage, setLandImage] = useState("");
  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });

  const rightSideBarStatus = useSelector((state: any) => {
    return state.modalReducer.rightSideBarStatus;
  });

  const favourablePlots = useSelector((state: any) => {
    return state.userReducer.favouratePlots;
  });

  const selectedPlot = useSelector((state: any) => {
    return state.plotReducer.selectedPlot;
  });

  const boughtPlots = useSelector((state: any) => {
    return state.plotReducer.boughtPlots;
  });

  const [amenities, setAmenities] = useState<string[] | []>([]);
  const [url, setUrl] = useState("");
  const [xcoord, setXcoord] = useState(0);
  const [ycoord, setYcoord] = useState(0);
  const [propertyType, setPropertyType] = useState<string>("");
  const [zoneType, setZoneType] = useState<string>("");
  const getUserDetails = async () => {
    try {
      const request = await axios.post(`${BASE_URL}/user/getUser`, {
        token: authToken
      });
      return request.data.user;
    } catch (error) {
      console.log(error);
    }
  };

  const handleLike = () => {
    if (!authToken) {
      dispatch(
        setAlertModalMsg({
          text: "Please login to add to favourites.",
          buttonText: "OK",
          onPress: () => dispatch(setAlertModalStatus(false)),
          heading: "Alert"
        })
      );
      dispatch(setCheckoutModalStatus(false));
      dispatch(setAlertModalStatus(true));
      return;
    }

    dispatch(setLoadingModalStatus(true));
    axios
      .post(`${BASE_URL}/user/likePlot`, {
        plot: selectedPlot?.left + "," + selectedPlot?.top,
        token: authToken
      })
      .then((response) => {
        getUserDetails().then((response) => {
          dispatch(setLoadingModalStatus(false));
          if (isLiked) {
            dispatch(
              setAlertModalMsg({
                text: "Item removed from favourites.",
                buttonText: "OK",
                onPress: () => dispatch(setAlertModalStatus(false)),
                heading: "Alert"
              })
            );
          } else {
            dispatch(
              setAlertModalMsg({
                text: "Item added to favourites.",
                buttonText: "OK",
                onPress: () => dispatch(setAlertModalStatus(false)),
                heading: "Alert"
              })
            );
          }
          dispatch({ type: SET_USER_DETAILS, payload: response });
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
        });
      })
      .catch((error) => {
        console.log(error);
        //@ts-ignore
        if (error?.response?.data?.statusCode === 403) {
          dispatch(
            setAlertModalMsg({
              text: "Your session expired, please login in again.",
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: "Alert"
            })
          );
        } else {
          dispatch(
            setAlertModalMsg({
              text: "Some error occurred, Try again.",
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: "Alert"
            })
          );
        }
        dispatch(setLoadingModalStatus(false));
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
      });
  };

  useEffect(() => {
    console.log("Selected Plot: ", selectedPlot);
    setIsLiked(false);
    if (selectedPlot === null) {
      return;
    }

    const x = selectedPlot?.left;
    const y = selectedPlot?.top;
    setXcoord(x);
    setYcoord(y);
    let diffY;
    let diffX;
    let temp: any = [];
    const DIFF_CONST = 30;

    Cameras.forEach((e, i) => {
      if (x >= e.startX && x <= e.endX && y >= e.startY && y <= e.endY) {
        setUrl(e.url);
      }
    });

    AmenitiesAxis.forEach((e, i) => {
      diffY = e?.top - y;
      diffX = e?.left - x;
      if (
        diffX < DIFF_CONST &&
        diffX > -DIFF_CONST &&
        diffY < DIFF_CONST &&
        diffY > -DIFF_CONST
      ) {
        temp.push(AmenitiesNames[i]);
      }
    });

    setAmenities(temp);
    if (selectedPlot) {
      let type, zone;

      if (ComPlotAxis.includes(selectedPlot.left + "," + selectedPlot.top)) {
        type = "com";
      } else {
        type = "res";
      }
      setPropertyType(type === "com" ? "Commercial" : "Residential");
      if (RedZoneAxis.includes(selectedPlot.left + "," + selectedPlot.top)) {
        zone = "red";
      } else if (
        YellowZoneAxis.includes(selectedPlot.left + "," + selectedPlot.top)
      ) {
        zone = "yellow";
      } else if (
        GreenZoneAxis.includes(selectedPlot.left + "," + selectedPlot.top)
      ) {
        zone = "green";
      } else {
        zone = "blue";
      }
      setZoneType(zone);
      setLandImage(LandImage[zone + "-" + type]);
    } else {
      setLandImage(LandImage["red-com"]);
    }
  }, [selectedPlot]);

  useEffect(() => {
    console.log(
      "Favourate Plots: ",
      favourablePlots.includes(selectedPlot?.left + "," + selectedPlot?.top)
    );
    if (favourablePlots.length > 0) {
      setIsLiked(
        favourablePlots.filter(
          (value) => value === selectedPlot?.left + "," + selectedPlot?.top
        ).length > 0
          ? true
          : false
      );
    }
  }, [favourablePlots, selectedPlot]);

  useEffect(() => {
    if (boughtPlots) {
      setBoughtPlotsKeys(Object.keys(boughtPlots));
    }
  }, [boughtPlots]);

  return (
    <Components.MainContainer status={rightSideBarStatus}>
      <Components.CloseButton
        onClick={() => {
          dispatch(setRightSideBarStatus(!rightSideBarStatus));
          dispatch(setSelectedPlot(null));
        }}
      >
        x
      </Components.CloseButton>
      <Components.GradientContainer>
        <Components.Heading>LAND DETAILS</Components.Heading>
        <img src={landImage} alt="exarta-land" style={{ width: "300px" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "90%"
          }}
        >
          {/* <Components.Heading style={{ opacity: "0.7" }}>
            Size 3x3
          </Components.Heading>
          <Components.Heading style={{ fontWeight: "1000" }}>
            8.1 ETH
          </Components.Heading> */}
        </div>
      </Components.GradientContainer>
      <br />
      <ReactPlayer
        url={url}
        width={"85%"}
        height={"150px"}
        controls={true}
        playing={rightSideBarStatus}
        muted={true}
        style={{ margin: "auto" }}
      />
      <br />
      <Components.Heading>PLOT DETAILS</Components.Heading>
      <br />
      <div style={{ width: "75%", margin: "auto" }}>
        <PlotDetailsDiv>
          Location:{" "}
          <PlotDetailsText>
            {xcoord}x,{ycoord}y
          </PlotDetailsText>
        </PlotDetailsDiv>
        <PlotDetailsDiv>
          Zone: <PlotDetailsText>{zoneType?.toUpperCase()}</PlotDetailsText>
        </PlotDetailsDiv>
        <PlotDetailsDiv>
          Type: <PlotDetailsText>{propertyType?.toUpperCase()}</PlotDetailsText>
        </PlotDetailsDiv>
        <PlotDetailsDiv>
          Price:{" "}
          <PlotDetailsText>
            {formatCurrency(
              getPlotPrice(selectedPlot?.left + "," + selectedPlot?.top)
            ) + " $"}
          </PlotDetailsText>
        </PlotDetailsDiv>
      </div>
      <br />
      <Components.Heading>NEARBY AMENITIES</Components.Heading>
      <div
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "space-around",
          margin: "20px auto"
        }}
      >
        <img src={TeaLogo} />
        <img src={BuildingLogo} />
        <img src={PetLogo} />
        <img src={FoodLogo} />
        <img src={TrainLogo} />
      </div>
      {/* <Components.Heading
        style={{
          width: "80%",
          margin: "10px auto",
          textAlign: "left",
          fontSize: "12px",
        }}
      >
        This is an undiscovered piece of a land of size 3x3 at EXARTA's
        neighborhood. It is located on the eastern side of Hawa, a modern
        society. Hawa is known all over EXARTA's as the 'Future city'.
      </Components.Heading> */}
      {/* <br /> */}
      {/* <div style={{ width: "80%", marginLeft: "30px" }}>AMENITIES:</div> */}
      <ul style={{ width: "80%", marginLeft: "50px" }}>
        {amenities.map((e, i) => {
          return (
            <li style={{ fontSize: "13px" }} key={i}>
              {e}
            </li>
          );
        })}
      </ul>
      <div
        style={{
          width: "100%",
          position: "sticky",
          bottom: "0",
          left: "0",
          backgroundColor: theme.palette.primary.main
        }}
      >
        <div
          style={{
            display: "flex",
            width: "80%",
            justifyContent: "space-around",
            margin: "20px auto",
            paddingBottom: "10px",
            bottom: "0px",
            left: "0",
            backgroundColor: theme.palette.primary.main
          }}
        >
          <Components.BorderButton
            style={{
              flex: "8"
            }}
            onClick={() => {
              if (
                !boughtPlotsKeys.includes(
                  selectedPlot?.left + "," + selectedPlot?.top
                )
              ) {
                dispatch(setCheckoutModalStatus(true));
                dispatch(setRightSideBarStatus(false));
              } else {
                handleLike();
              }
            }}
          >
            {!boughtPlotsKeys.includes(
              selectedPlot?.left + "," + selectedPlot?.top
            )
              ? "BUY"
              : "LIKE"}
          </Components.BorderButton>
          &nbsp;&nbsp;&nbsp;
          <Components.CustomLikeButton
            style={{ flex: "2" }}
            onClick={handleLike}
            active={isLiked}
          >
            <CustomfavoriteIcon active={isLiked} />
          </Components.CustomLikeButton>
        </div>
      </div>
    </Components.MainContainer>
  );
};

export default RightSideBar;
