import { FC } from "react";
import { styled } from "@mui/material/styles";

const Container = styled("div")(({ theme }) => ({
  height: "30px",
  width: "fit-content",
  borderRadius: "10px",
  border: "solid 1px " + theme.palette.primary.dark,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "10px",
  cursor: "pointer",
}));

const Tab = styled("div")<{ active: boolean }>(({ theme, active }) => ({
  height: "100%",
  width: active ? "40px" : "20px",
  borderRadius: "7px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "10px",
  cursor: "pointer",
  backgroundColor: theme.palette.primary.dark,
  opacity: active ? "1" : "0",
}));

const Switch: FC<{
  option1: any;
  option2: any;
  currentTab: Number;
  onChange: () => void;
}> = ({ option1, option2, currentTab, onChange }) => {
  return (
    <Container>
      <Tab
        active={currentTab === 0 ? true : false}
        onClick={() => {
          onChange();
        }}
        sx={{ color: "#fff" }}
      >
        {option1}
      </Tab>
      <Tab
        active={currentTab === 1 ? true : false}
        onClick={() => {
          onChange();
        }}
        sx={{ color: "#fff" }}
      >
        {option2}
      </Tab>
    </Container>
  );
};

export default Switch;
