import { FC, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import LangImg from "../../../../assets/land.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../../../redux/actions/cartActions";
import { formatCurrency, getPlotPrice, getPlotZone } from "../../../../utils";

const CheckkoutDetailsContainer = styled("div")<{
  isMobile: boolean;
  activeTab: number;
}>(({ theme, isMobile, activeTab }) => ({
  width: isMobile ? "100%" : "45%",
  height: "calc(100vh - 70px)",
  overflowY: "auto",
  padding: "0px 40px",

  paddingBottom: "50px",

  display: isMobile && activeTab === 1 ? "none" : "block",

  marginRight: isMobile ? "0px" : "10px",

  "::-webkit-scrollbar": {
    width: "7px"
  },

  "::-webkit-scrollbar-track": {
    marginTop: "40px"
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
    borderRadius: "10px"
  },

  [theme.breakpoints.down("sm")]: {
    padding: "0px 20px"
  }
}));

const Heading = styled("h1")(({ theme }) => ({
  fontSize: "18px",
  marginTop: "20px",
  marginBottom: "25px",
  fontWeight: "500",
  textTransform: "uppercase"
}));

const ItemBox = styled("div")<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  display: "flex",
  alignItems: "center",
  background: theme.palette.primary.light,
  width: isMobile ? "100%" : "85%",
  height: "fit-content",
  borderRadius: "20px",
  marginBottom: isMobile ? "40px" : "40px",
  position: "relative"
}));

const Circle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.background.itemBoxColor,
  border: "1px solid" + theme.palette.background.paper,
  height: "25px",
  width: "25px",
  borderRadius: "15px",
  position: "absolute",
  right: "-5px",
  top: "-8px",
  cursor: "pointer"
}));

const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.background.itemBoxColor,
  width: "fit-content",
  padding: "30px",
  borderRadius: "20px",

  [theme.breakpoints.down("md")]: {
    padding: "15px"
  }
}));

const Image = styled("img")(({ theme }) => ({
  height: "60px",
  width: "60px"
}));

const DetailsContainer = styled("div")<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: isMobile ? "100%" : "75%",
    height: "100%",
    padding: isMobile ? "20px 5px 20px 5px" : "20px 25px 20px 10px"
  })
);

const LandName = styled("h4")<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  fontSize: isMobile ? "10px" : "14px",
  fontWeight: "500",
  textTransform: "uppercase",
  textAlign: "left",
  marginBottom: isMobile ? "5px" : "0"
}));

const LandPriceContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "80%",
  marginRight: "20px"
}));

const LandDescription = styled("h4")<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    fontSize: "8px",
    fontWeight: "600",
    textTransform: "uppercase",
    marginTop: "5px",
    opacity: "0.5",
    width: "75%",
    display: isMobile ? "none" : "flex"
  })
);

const PriceText = styled("h4")(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "600",
  textTransform: "uppercase",
  color: theme.palette.primary.dark
}));

const TotalBox = styled("div")<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.light,
    width: isMobile ? "100%" : "85%",
    borderRadius: "20px",
    height: "50px"
  })
);

const TotalHeading = styled("div")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",
  textTransform: "uppercase",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "15px"
}));

const TotalBorder = styled("div")(({ theme }) => ({
  height: "100%",
  border: "1px solid" + theme.palette.primary.dark,
  color: theme.palette.primary.dark,
  fontSize: "16px",
  fontWeight: "600",
  flex: 1,
  borderRadius: "20px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const BorderButton = styled("div")(({ theme }) => ({
  height: "50px",
  width: "100%",
  borderRadius: "20px",
  border: "solid 1px " + theme.palette.primary.dark,
  color: theme.palette.primary.dark,
  fontWeight: "600",
  fontSize: "18px",
  margin: "25px auto",
  padding: "0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  marginTop: "40px"
}));

type ItemDetailsProps = {
  isMobile: boolean;
  acitveTab: number;
  changeMobileTab(): void;
};

type CartPlot = {
  name: string;
  zone: string;
  type: string;
  price: number;
};

const ItemDetails: FC<ItemDetailsProps> = ({
  isMobile,
  acitveTab,
  changeMobileTab
}) => {
  const [cartPlots, setCartPlots] = useState<CartPlot[]>([]);
  const dispatch = useDispatch();

  const removeFromCartHandler = (cordinates: string) => {
    dispatch(removeFromCart(cordinates));
  };

  const plots = useSelector((state: any) => {
    return state.cartReducer.plots;
  });

  console.log(cartPlots);

  useEffect(() => {
    let temp: any = [];
    plots.forEach((e) =>
      temp.push({
        name: e,
        price: getPlotPrice(e),
        zone: getPlotZone(e)?.zone,
        type: getPlotZone(e)?.type
      })
    );
    setCartPlots(temp);
  }, [plots]);

  const calculateTotalAmount = () => {
    const sum = cartPlots.reduce((accumulator, object) => {
      return accumulator + object.price;
    }, 0);

    return sum;
  };

  useEffect(() => {
    calculateTotalAmount();
    //  eslint-disable-next-line
  }, [cartPlots]);

  return (
    <CheckkoutDetailsContainer isMobile={isMobile} activeTab={acitveTab}>
      <Heading>Summary</Heading>
      {cartPlots.map((item, index) => {
        return (
          <ItemBox isMobile={isMobile} key={index}>
            <Circle
              onClick={() => removeFromCartHandler(cartPlots[index].name)}
            >
              <CloseIcon sx={{ height: "15px" }} />
            </Circle>
            <ImageContainer>
              <Image src={LangImg} />
            </ImageContainer>
            <DetailsContainer isMobile={isMobile}>
              <LandName isMobile={isMobile}>
                Co-ordinates ({item.name})
              </LandName>
              <LandName isMobile={isMobile}>Type ({item.type})</LandName>
              <LandName isMobile={isMobile}>Zone ({item.zone})</LandName>
            </DetailsContainer>
            <LandPriceContainer>
              <PriceText>{formatCurrency(item.price)} $</PriceText>
            </LandPriceContainer>
          </ItemBox>
        );
      })}

      <TotalBox isMobile={isMobile}>
        <TotalHeading style={{ marginLeft: "15px" }}>Summary</TotalHeading>
        <TotalBorder>{formatCurrency(calculateTotalAmount())} $</TotalBorder>
      </TotalBox>

      {isMobile && (
        <BorderButton
          onClick={changeMobileTab}
          style={{ marginBottom: "70px" }}
        >
          Proceed To Payment
        </BorderButton>
      )}
    </CheckkoutDetailsContainer>
  );
};

export default ItemDetails;
