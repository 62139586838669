/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { alpha, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  PaymentElement,
  CardElement,
  useElements,
  useStripe,
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useThemeSwitch } from "../../../../hooks/switchTheme";
import { useNavigate } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";

import MasterCardIcon from "../../../../assets/Mastercard.svg";
import PaypalCardIcon from "../../../../assets/Paypal.svg";
import { BASE_URL } from "../../../../utils/api";
import { useTheme } from "@mui/material";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setLoadingModalStatus,
} from "../../../../redux/actions/modalActions";
import { emptyCart } from "../../../../redux/actions/cartActions";
import { clearAuth, clearUser } from "../../../../redux/actions/authActions";

const CardtDetailsContainer = styled("div")<{
  isMobile: boolean;
  acitveTabForMobile: number;
}>(({ theme, isMobile, acitveTabForMobile }) => ({
  display: isMobile && acitveTabForMobile === 0 ? "none" : "block",
  flexDirection: "column",
  width: isMobile ? "100%" : "45%",
  height: "calc(100vh - 70px)",
  padding: isMobile ? "0px 40px" : "0px",
  overflowY: "auto",

  "::-webkit-scrollbar": {
    width: "7px",
  },

  "::-webkit-scrollbar-track": {
    marginTop: "40px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
    borderRadius: "10px",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "0px 20px",
  },
}));

const Heading = styled("h1")(({ theme }) => ({
  fontSize: "17px",
  marginTop: "20px",
  marginBottom: "25px",
  fontWeight: "500",
  textTransform: "uppercase",
}));

const Tabs = styled("div")<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  // width:"60%",
  width: "fit-content",
  // background: "#311c31",
  height: "40px",
  borderRadius: "10px",
}));

type TabProps = {
  status: boolean;
};

const Tab1 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
  background: "#c9bec7",
  height: "40px",
  borderRadius: "10px",
  padding: "0px 10px",
}));

const TabCardImage = styled("img")(({ theme }) => ({
  height: "25px",
  width: "25px",
}));
const TabCardText = styled("h4")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "400",
  textTransform: "uppercase",
  color: "black",
  marginLeft: "7px",
  lineHeight: "10px",
}));

const Row = styled("div")<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  disply: "flex",
  width: "100%",
  flexDirection: isMobile ? "column" : "row",
  justifyContent: "space-between",
}));

const TermsText = styled("p")(({ theme }) => ({
  color: "#665a68",
  fontSize: "12px",
  fontWeight: "600",
}));

const UnderLine = styled("div")(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.text.primary,
  marginTop: "10px",
  opacity: "0.5",
}));

const PayNowButton = styled("div")(({ theme }) => ({
  height: "50px",
  width: "100%",
  borderRadius: "30px",
  background: theme.palette.primary.dark,
  color: theme.palette.text.primary,
  margin: "25px auto",
  padding: "0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "20px",
  fontWeight: "600",
  cursor: "pointer",
  font: "normal normal normal Montserrat",
  letterSpacing: "3.43px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.paper,
  },
}));

type CardDetailsProps = {
  isMobile: boolean;
  acitveTabForMobile: number;
  changeMobileTab(): void;
};

const CardDetails: FC<CardDetailsProps> = ({
  isMobile,
  acitveTabForMobile,
  changeMobileTab,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [cardNumber, setCardNumber] = useState("");
  const [cartPlots, setCartPlots] = useState<string[]>([]);
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);

  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });
  const plots = useSelector((state: any) => {
    return state.cartReducer.plots;
  });

  const theme = useTheme();

  const stripe = useStripe();

  const elements = useElements();

  const dispatch = useDispatch();

  const [mode] = useThemeSwitch();
  const [redirectBack, setRedirectedBack] = useState(false);
  const navigate = useNavigate();
  //getting payment intent from backend
  const currentUserId = useSelector((state: any) => {
    return state.userReducer.uid;
  });

  const inputStyle = {
    style: {
      base: {
        fontSize: "20px",
        padding: "7px",
        paddingTop: "0px",
        color: theme.palette.text.primary,
        "::placeholder": {
          color: alpha(theme.palette.text.primary, 0.5),
          value: "Enter Card Number",
        },
      },
    },
  };

  useEffect(() => {
    const cardNumberElement = elements?.create("cardNumber");
    const cardExpiryElement = elements?.create("cardExpiry");
    const cardCvcElement = elements?.create("cardCvc");

    cardNumberElement?.mount("#card-number");
    cardExpiryElement?.mount("#card-expiry");
    cardCvcElement?.mount("#card-cvc");
    if (window.location.href.includes("payment_intent")) {
      dispatch(setLoadingModalStatus(true));
      confirmPaymentAfterRedirections();
    }
  }, []);

  useEffect(() => {
    let temp: any = [];
    plots.forEach((e) => e);
    setCartPlots(temp);
    // get current url if it contain payment intent start loadingModalStatus
  }, [plots]);
  const confirmPaymentAfterRedirections = async () => {
    // logic for 3d payments
    const url = window.location.href;
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const paymentIntentId = urlParams.get("payment_intent");
    const paymentIntentClientSecret = urlParams.get(
      "payment_intent_client_secret"
    );
    // const error = urlParams.get("error");
    // const errorDescription = urlParams.get("error_description");
    // if (error) {
    //   dispatch(setLoadingModalStatus(false));
    //   dispatch(setAlertModalStatus(true));
    //   dispatch(
    //     setAlertModalMsg(
    //       errorDescription || "Some error occurred.Please try again."
    //     )
    //   );
    //   return;
    // }
    if (paymentIntentId && paymentIntentClientSecret) {
      // confirm the payment
      let confirmationRes = await axios.post(
        `${BASE_URL}/payments/confirmPayment`,
        {
          clientSecret: paymentIntentClientSecret,
          intentId: paymentIntentId,
          token: authToken,
        }
      );

      if (confirmationRes?.data?.status === false) {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: confirmationRes?.data?.message ? confirmationRes?.data?.message : "",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        return;
      } else {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: "Payment Successful.",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        dispatch(emptyCart());
        navigate("/");
        return;
      }
    }
  };
  const payNow = async () => {
    try {
      dispatch(setLoadingModalStatus(true));
      // get authented user email address
      let response;
      try {
        response = await axios.put(`${BASE_URL}/user/getUserFromId`, {
          id: currentUserId,
        });
      } catch (e) {
        console.log("error in getting user email address");
      }
      const product = {
        coordinates: plots,
        token: authToken,
        user: response.data.user || null,
      };

      if (plots.length < 1) {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: "Your cart is empty.",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        return;
      }

      //creating payment method object

      const cardElement = elements?.getElement(CardNumberElement);

      let paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        //@ts-ignore
        card: cardElement,
      });

      if (paymentMethod?.error) {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: paymentMethod.error.message ? paymentMethod.error.message : "",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        return;
      }

      if (!checkBoxStatus) {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: "Check the box for Terms & Conditions.",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        return;
      }

      let res = await axios.post(`${BASE_URL}/payments/createPayment`, product);

      if (res?.data?.status === false) {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: res?.data?.message ? res?.data?.message : "",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        return;
      }

      //executing payment intent

      const data = {
        clientSecret: res.data.paymentIntent.client_secret,
        id: res.data.paymentIntent.id,
        paymentMethodId: paymentMethod?.paymentMethod.id,
      };
      console.log("running confirmcardpayment");
      let txRes = await stripe?.confirmCardPayment(
        data.clientSecret,
        {
          payment_method: data.paymentMethodId,
          return_url: "https://landsales.exarta.com/checkout",
        },
        {
          handleActions: false,
        }
      );
      console.log("3d secure is required", txRes?.paymentIntent);
      if (txRes?.paymentIntent?.status === "requires_action") {
        const redirect_url =
          txRes?.paymentIntent?.next_action?.redirect_to_url?.url || "/";
        console.log("redirect_url", redirect_url);
        window.location.href = redirect_url;
        return;
      }
      if (txRes?.error) {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: txRes?.error?.message ? txRes?.error?.message : "",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        return;
      }
      console.log("In case 3d secure not required!");
      let confirmationRes = await axios.post(
        `${BASE_URL}/payments/confirmPayment`,
        {
          clientSecret: data.clientSecret,
          intentId: data.id,
          token: authToken,
        }
      );

      if (confirmationRes?.data?.status === false) {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: confirmationRes?.data?.message ? confirmationRes?.data?.message : "",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        return;
      } else {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: "Payment Successful.",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        dispatch(emptyCart());
        navigate("/");
        return;
      }
    } catch (e) {
      //@ts-ignore
      if (e?.response?.data?.statusCode === 403) {
        dispatch(
          setAlertModalMsg({
            text: "Your session expired, please login in again.",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
      } else {
        dispatch(
          setAlertModalMsg({
            text: "Some error occurred, Try again.",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
      }
      dispatch(setLoadingModalStatus(false));
      dispatch(setCheckoutModalStatus(false));
      dispatch(setAlertModalStatus(true));
    }
  };

  return (
    <CardtDetailsContainer
      isMobile={isMobile}
      acitveTabForMobile={acitveTabForMobile}
    >
      <Heading>Payment Method</Heading>
      <Tabs isMobile={isMobile}>
        <Tab1>
          <TabCardImage src={MasterCardIcon} />
          <TabCardText>Credit / Debit Card</TabCardText>
        </Tab1>
      </Tabs>

      <Row
        style={{ margin: "10px 0px", marginTop: "30px" }}
        isMobile={isMobile}
      >
        <Heading
          style={{ margin: "8px 0px", color: theme.palette.primary.dark }}
        >
          Card Number
        </Heading>
        <CardNumberElement options={inputStyle} />
        <UnderLine />

        <div style={{ display: "flex", marginTop: "20px" }}>
          <div style={{ flex: 1 }}>
            <Heading
              style={{ margin: "8px 0px", color: theme.palette.primary.dark }}
            >
              Card CVC
            </Heading>
            <CardCvcElement options={inputStyle} />
            <UnderLine />
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ flex: 1 }}>
            <Heading
              style={{ margin: "8px 0px", color: theme.palette.primary.dark }}
            >
              Card Expiry
            </Heading>
            <CardExpiryElement options={inputStyle} />
            <UnderLine />
          </div>
        </div>
      </Row>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Checkbox
          onChange={() => {
            setCheckBoxStatus(!checkBoxStatus);
          }}
          sx={{ color: "#bc679c", "&.Mui-checked": { color: "#bc679c" } }}
        />
        <TermsText>
          I have read, understood and agree to the
          <span
            onClick={() => {
              window.open("https://exarta.com/terms-and-conditions/");
            }}
            style={{ color: "#bc679c", cursor: "pointer" }}
          >
            {" "}
            Terms & Conditions
          </span>{" "}
          of Sale.
        </TermsText>
      </div>

      <PayNowButton
        onClick={() => {
          payNow();
        }}
        style={{ marginBottom: "70px" }}
      >
        Pay Now
      </PayNowButton>
    </CardtDetailsContainer>
  );
};

const stripePromise = loadStripe(
  "pk_live_51LUs20JOK1HXlIC80f1CKOYphLwhqklj9FZKXLlUJ22djAbaqLPdjXLc9EShnTJodbuYGsrTM5hrzMV11RO5Zp3n00OPyNyblQ"
);

const Wrapper: FC<CardDetailsProps> = ({
  isMobile,
  acitveTabForMobile,
  changeMobileTab,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <CardDetails
        isMobile={isMobile}
        acitveTabForMobile={acitveTabForMobile}
        changeMobileTab={changeMobileTab}
      />
    </Elements>
  );
};

export default Wrapper;
