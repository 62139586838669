import { FC } from "react";
import { styled } from "@mui/material/styles";
// import WalletButton from "../WalletButtonMobile";
import Logo from "../../../../assets/logo.svg";
import ProfileButton from "../ProfileButton";
import CartButton from "../CartButton";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  position: "fixed",
  left: "0",
  top: "0",
  right: "0",
  zIndex: "1500",
  alignItems: "center",
  height: "65px",
  backgroundColor: theme.palette.primary.main,
  padding: "0px 7px",
}));

const LogoElement = styled("div")(({ theme }) => ({
  width: "100%",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0px auto",
  marginLeft: "7px",
}));

const LogoImage = styled("img")(({ theme }) => ({
  height: "28px",
}));

const LogoName = styled("div")(({ theme }) => ({
  fontSize: "20px",
  marginLeft: "10px",
  marginRight: "15px",
}));

const Index: FC = () => {
  return (
    <Container>
      <div>
        <LogoElement>
          <LogoImage src={Logo} />
          <LogoName style={{ fontFamily: "Astera" }}>EXARTA</LogoName>
        </LogoElement>
      </div>
      <ProfileButton />
      <CartButton />
    </Container>
  );
};

export default Index;
