import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { isValidEmail } from "../../../../utils";
import {
  ErrorText,
  FormHeading,
  FormSubHeading,
  Input,
  ParentInput,
} from "../..";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setLoadingModalStatus,
} from "../../../../redux/actions/modalActions";
import { useDispatch } from "react-redux";

const FormContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const LoginButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{
  disabled: boolean;
}>(({ theme, disabled }) => ({
  height: "50px",
  width: "50%",
  borderRadius: "30px",
  background: theme.palette.primary.dark,
  color: theme.palette.text.primary,
  margin: "25px auto",
  padding: "0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "20px",
  fontWeight: "600",
  cursor: disabled ? "not-allowed" : "pointer",
  font: "normal normal normal Montserrat",
  letterSpacing: "3.43px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.paper,
  },
}));

const ForgotPasswordForm: FC<{ setPage: (pageNumber: number) => void }> = ({
  setPage,
}) => {
  const firebase = useSelector((state: any) => {
    return state.authReducer.firebaseObj;
  });

  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState(true);

  const dispatch = useDispatch();

  const forgetPassword = () => {
    if (firebase) {
      dispatch(setLoadingModalStatus(true));
      const auth = getAuth(firebase);
      sendPasswordResetEmail(auth, email)
        .then(async () => {
          dispatch(setLoadingModalStatus(false));
          dispatch(
            setAlertModalMsg({
              text: "Password reset email sent to your email, please verify.",
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: 'Alert',
            })
          );
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
          setPage(0);
        })
        .catch((err) => {
          dispatch(setLoadingModalStatus(false));
          dispatch(
            setAlertModalMsg({
              text: err.message,
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: 'Alert',
            })
          );
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
          console.log(err);
        });
    }
  };
  const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidEmail(event.target.value)) {
      setEmailStatus(true);
    } else {
      setEmailStatus(false);
    }
  };

  return (
    <>
      <FormContainer>
        <FormHeading>Forgot Password </FormHeading>
        <FormSubHeading>
          Enter the email address associated with your account
        </FormSubHeading>

        <ParentInput>
          <Input
            placeholder="Email ID"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e);
            }}
          />
          <ErrorText>
            {!emailStatus &&
              email.length > 0 &&
              "Please enter a valid email address"}
          </ErrorText>
        </ParentInput>
        <LoginButton
          disabled={emailStatus && email.length > 0 ? false : true}
          onClick={() => {
            if (emailStatus && email.length > 0) {
              forgetPassword();
            }
          }}
        >
          NEXT
        </LoginButton>
      </FormContainer>
    </>
  );
};

export default ForgotPasswordForm;
