/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes, Navigate } from "react-router-dom";
import { Modal } from "@mui/material";

import Home from "../pages/Home";
import AuthScreen from "../pages/AuthScreen";
import { initializeApp } from "firebase/app";
import {
  clearAuth,
  clearUser,
  setFirebaseObj,
} from "../redux/actions/authActions";
import Checkout from "../pages/Checkkout";
import Profile from "../pages/Profile";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
} from "../redux/actions/modalActions";
import AlertModal from "../components/AlertModal";
import LoadingModal from "../components/LoadingModal";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

// const firebaseConfig = {
//   apiKey: "AIzaSyDHX2uEG26SLuYbPu2CBBYRXtb_WlpcDIQ",
//   authDomain: "my-reads-app-redux.firebaseapp.com",
//   databaseURL: "https://my-reads-app-redux-default-rtdb.firebaseio.com",
//   projectId: "my-reads-app-redux",
//   storageBucket: "my-reads-app-redux.appspot.com",
//   messagingSenderId: "337935905383",
//   appId: "1:337935905383:web:c80044dbfd688eb55f5ac2",
// };

// New config 
const firebaseConfig = {
  apiKey: "AIzaSyDqXlFl33fh6lo5qGuDOPyRlgaX6cFn3JM",
  authDomain: "exarta-landsales.firebaseapp.com",
  projectId: "exarta-landsales",
  storageBucket: "exarta-landsales.appspot.com",
  messagingSenderId: "991291440228",
  appId: "1:991291440228:web:7777746f60ff3946393371",
  measurementId: "G-VR192M20EK"
};


const GetRoutes = () => {
  return useRoutes([
    {
      path: "/",
      children: [
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "",
          element: <Navigate to={"/home"} />,
        },
        {
          path: "login",
          element: <AuthScreen />,
        },
        {
          path: "checkout",
          element: <Checkout />,
        },
        {
          path: "profile",
          children: [
            {
              path: ":userId",
              element: <Profile />,
            },
            {
              path: "",
              element: <Navigate to={"/home"}/>,
            },
            {
              path: "*",
              element: <Navigate to={"/home"}/>,
            },
          ],
        },
        {
          path: "*",
          element: <Navigate to={"/home"} />,
        },
      ],
    },
  ]);
};

const Router: FC = () => {
  
  const alertModalStatus = useSelector((state: any) => {
    return state.modalReducer.alertModalStatus;
  });
  const loadingModalStatus = useSelector((state: any) => {
    return state.modalReducer.loadingModalStatus;
  });
  const firebase = useSelector((state: any) => {
    return state.authReducer.firebaseObj;
  });

  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });

  const expiryTime = useSelector((state: any) => {
    return state.authReducer.expiryTime;
  });

  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const firebase = initializeApp(firebaseConfig);
    dispatch(setFirebaseObj(firebase));
  }, []);

  useEffect(() => {
    if (authToken && expiryTime) {
      if (Date.now() >= expiryTime) {
        dispatch(clearAuth());
        dispatch(clearUser());
        dispatch(
          setAlertModalMsg({
            text: "Your session expired, please login in again.",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
        navigate("/login");
      }
    }
  }, [authToken, expiryTime]);

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      {firebase ? GetRoutes() : null}
      <Modal
        open={alertModalStatus}
        onClose={() => {
          dispatch(setAlertModalStatus(false));
        }}
        style={{ zIndex: "1000000" }}
      >
        <>
          <AlertModal />
        </>
      </Modal>
      <Modal open={loadingModalStatus} style={{ zIndex: "1000000" }}>
        <>
          <LoadingModal />
        </>
      </Modal>
    </div>
  );
};

export default Router;
