import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useTheme } from "@mui/material/styles";
import {
  changeAuthToken,
  clearAuth,
  clearUser,
  setUserFirebaseDetails,
} from "../../../../redux/actions/authActions/index";
import { isValidEmail } from "../../../../utils";
import {
  ErrorText,
  FormHeading,
  FormSubHeading,
  Input,
  ParentInput,
} from "../..";

import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setLoadingModalStatus,
} from "../../../../redux/actions/modalActions";

const FormContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const RememberForgetDiv = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "20px",
  cursor:"pointer",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0px 20px",
  },
}));

const RememberDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const FormSubText = styled("p")(({ theme }) => ({
  color: "#665a68",
  fontSize: "12px",
  fontWeight: "600",
}));

const LoginButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{
  disabled: boolean;
}>(({ theme, disabled }) => ({
  height: "50px",
  width: "50%",
  borderRadius: "30px",
  background: theme.palette.primary.dark,
  color: theme.palette.text.primary,
  margin: "25px auto",
  padding: "0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  fontWeight: "600",
  cursor: disabled ? "not-allowed" : "pointer",
  font: "normal normal normal Montserrat",
  letterSpacing: "3.43px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.paper,
  },
}));

const LoginForm: FC<{ setPage: (pageNumber: number) => void }> = ({
  setPage,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailStatus, setEmailStatus] = useState(true);
  const [passwordStatus, setPasswordStatus] = useState(true);
  const [rememberPassword,setRememberPassword] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();

  const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidEmail(event.target.value)) {
      setEmailStatus(true);
    } else {
      setEmailStatus(false);
    }
  };

  const firebase = useSelector((state: any) => {
    return state.authReducer.firebaseObj;
  });

  function SignInWithEmail() {
    if (firebase) {
      dispatch(setLoadingModalStatus(true));
      const auth = getAuth(firebase);
      signInWithEmailAndPassword(auth, email, password)
        .then(async (res) => {
          //@ts-ignore
          const expiry = res.user?.stsTokenManager.expirationTime;
          const token = await auth?.currentUser?.getIdToken(true);
          if (token) {
            dispatch(setUserFirebaseDetails(res));
            dispatch(changeAuthToken(token, expiry));
          }
          dispatch(setLoadingModalStatus(false));
        })
        .catch((err) => {
          dispatch(setLoadingModalStatus(false));
          dispatch(
            setAlertModalMsg({
              text: err.message,
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: 'Alert',
            })
          );
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
          console.log(err);
        });
    }
  }

  return (
    <>
      <FormContainer>
        <FormHeading>Account login</FormHeading>
        <FormSubHeading onClick={() => setPage(1)}>
          Don't have an account?
          <span style={{ color: "#bc679c" }}> Sign Up</span>{" "}
        </FormSubHeading>

        <ParentInput>
          <Input
            placeholder="Email ID"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e);
            }}
          />
          <ErrorText>
            {!emailStatus &&
              email.length > 0 &&
              "Please enter a valid email address"}
          </ErrorText>
        </ParentInput>

        <ParentInput>
          <Input
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              if (e.target.value.length >= 8) {
                setPasswordStatus(true);
              } else setPasswordStatus(false);
            }}
            type="password"
          />
          <ErrorText>
            {!passwordStatus &&
              password.length > 0 &&
              "Minimum length of password should be 8"}
          </ErrorText>
        </ParentInput>

        <RememberForgetDiv>
          <RememberDiv>
            <Checkbox
              sx={{
                color: "#bc679c",
                textAlign: "left",
                padding: "0px",
                "&.Mui-checked": { color: "#bc679c" },
              }}
              checked={rememberPassword}
              onChange={()=>{setRememberPassword(!rememberPassword)}}
            />
            <FormSubText style={{ marginLeft: "7px" }} onClick={()=>{setRememberPassword(!rememberPassword)}}>
              Remember Password
            </FormSubText>
          </RememberDiv>

          <RememberDiv>
            <FormSubText
              onClick={() => setPage(2)}
              style={{
                width: "fit-content",
                textAlign: "right",
                color: theme.palette.primary.dark,
              }}
            >
              Forget Password
            </FormSubText>
          </RememberDiv>
        </RememberForgetDiv>

        <LoginButton
          disabled={
            emailStatus && email.length > 0 && password.length > 0
              ? false
              : true
          }
          onClick={() => {
            if (emailStatus && email.length > 0 && password.length > 0) {
              SignInWithEmail();
            }
          }}
        >
          LOGIN
        </LoginButton>
      </FormContainer>
    </>
  );
};

export default LoginForm;
