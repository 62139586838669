import { FC } from "react";
import { styled } from "@mui/material/styles";

const Container = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "300px",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "50px 30px",
  borderRadius: "20px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const Loader = () => {
  return (
    <div className="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const LoadingModal: FC = () => {
  return (
    <Container>
      <Loader />
      Loading...
    </Container>
  );
};

export default LoadingModal;
