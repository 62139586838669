/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton } from "@mui/material";
import { Logo, DarkModeLogo, LightModeLogo } from "../../../../assets/index";
import AllPlotsAxis from "../Categories/allPlotAxis.json";

import Switch from "../../../../components/Switch";
import { useThemeSwitch } from "../../../../hooks/switchTheme";
import { useDispatch, useSelector } from "react-redux";
import {
  setPropertyType,
  setSelectablePlots,
  setSelectedPlot,
  setZoneType,
  setZoomToElement
} from "../../../../redux/actions/plotActions";
import * as Components from "./components";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setRightSideBarStatus
} from "../../../../redux/actions/modalActions";
import infoImg from "../../../../assets/information.svg";
import { rgbToHex, styled } from "@mui/material/styles";
import "../../../../App.css";
import { Tooltip } from "@mui/material";

const DesktopOptionsBar: FC = () => {
  const [availabilityStatus, setAvailabilityStatus] = useState(true);
  const [themeStatus, setThemeStatus] = useState(false);
  const [xAxis, setXAxis] = useState<number>(-1);
  const [yAxis, setYAxis] = useState<number>(-1);
  const [, switchTheme] = useThemeSwitch();
  const theme = useTheme();

  const dispatch = useDispatch();

  const zoneType = useSelector((state: any) => {
    return state.plotReducer.selectedZoneType;
  });

  const propertyType = useSelector((state: any) => {
    return state.plotReducer.selectedPropertyType;
  });

  const changeAvailabilityStatus = () => {
    setAvailabilityStatus(!availabilityStatus);
    if (availabilityStatus) {
      dispatch(setPropertyType(null));
      dispatch(setZoneType(null));
    } else {
      dispatch(setPropertyType("all"));
    }
  };

  const changeThemeStatus = () => {
    setThemeStatus(!themeStatus);
  };

  useEffect(() => {
    if (theme) {
      //@ts-ignore
      theme?.palette?.mode === "light"
        ? setThemeStatus(true)
        : setThemeStatus(false);
    }
  }, [theme]);

  useEffect(() => {
    if (zoneType != null || propertyType != null) {
      setAvailabilityStatus(true);
    }
  }, [zoneType, propertyType]);

  const MuiImage = styled("img")(({ theme }) => ({
    cursor: "pointer",
    marginLeft: "8px"
  }));

  const TooltipContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }));

  const InfoTooltip = ({ text, tooltipStyle }) => {
    return (
      <Tooltip
        title={text}
        placement="right-start"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              ...tooltipStyle,
              width: "250px",
              backgroundColor: "#420C47",
              opacity: "80% !important",
              borderRadius: "12px"
            }
          },
          popper: {
            sx: {
              marginTop: "-7px !important"
            }
          },
          arrow: {
            sx: {
              color: "#420C47",
              opacity: "80% !important",
              top: "7px !important"
            }
          }
        }}
      >
        <div
          style={{
            paddingLeft: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <IconButton>
            {" "}
            <InfoOutlinedIcon
              fontSize="small"
              style={{
                fontWeight: "10px"
              }}
            />
          </IconButton>
        </div>
      </Tooltip>
    );
  };

  return (
    <Components.Container>
      <Components.LogoElement>
        <a
          href="https://exarta.com/"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "white"
          }}
          target="_blank"
          rel="noreferrer"
        >
          <Components.LogoImage src={Logo} />
          <Components.LogoName style={{ fontFamily: "Astera" }}>
            EXARTA
          </Components.LogoName>
        </a>
      </Components.LogoElement>

      <Components.GradientContainer>
        <TooltipContainer>
          <Components.Heading>GO TO CO-ORDINATE</Components.Heading>
          <InfoTooltip
            text="To navigate to a specific location on the map using coordinates."
            tooltipStyle={{
              fontSize: "14px",
              left: "26px !important"
            }}
          />
        </TooltipContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ flex: "0.5", padding: "0px 15px" }}>
            <Components.Input
              placeholder="x-axis"
              type="number"
              min={0}
              max={240}
              value={xAxis === -1 ? "" : xAxis}
              onChange={(e) => {
                if (
                  (parseInt(e.target.value) >= 0 &&
                    parseInt(e.target.value) <= 240) ||
                  e.target.value === ""
                ) {
                  setXAxis(parseInt(e.target.value));
                }
              }}
            />
          </div>
          <Components.VerticalDivider />
          <div style={{ flex: "0.5", padding: "0px 15px" }}>
            <Components.Input
              placeholder="y-axis"
              type="number"
              min={0}
              max={240}
              value={yAxis === -1 ? "" : yAxis}
              onChange={(e) => {
                if (
                  (parseInt(e.target.value) >= 0 &&
                    parseInt(e.target.value) <= 240) ||
                  e.target.value === ""
                ) {
                  setYAxis(parseInt(e.target.value));
                }
              }}
            />
          </div>
        </div>
        <Components.BorderButton
          onClick={() => {
            dispatch(setRightSideBarStatus(true));
            if (yAxis !== -1 && xAxis !== -1) {
              let plot = xAxis.toString() + "," + yAxis.toString();
              if (AllPlotsAxis.includes(plot)) {
                dispatch(setSelectedPlot({ top: yAxis, left: xAxis }));
                dispatch(setZoomToElement(true));
              } else {
                dispatch(
                  setAlertModalMsg({
                    text: "Invalid Coordinates",
                    buttonText: "OK",
                    onPress: () => dispatch(setAlertModalStatus(false)),
                    heading: "Alert"
                  })
                );
                dispatch(setCheckoutModalStatus(false));
                dispatch(setAlertModalStatus(true));
              }
            }
          }}
        >
          GO TO CO-ORDINATE
        </Components.BorderButton>
      </Components.GradientContainer>

      {/* <Components.GradientContainer>
        <Components.Heading>FIND ADDRESS</Components.Heading>
        <div style={{ padding: "0px 15px" }}>
          <Components.Input placeholder="value" />
        </div>
        <Components.BorderButton>HIGHLIGHT LAND</Components.BorderButton>
      </Components.GradientContainer> */}

      <Components.GradientContainer>
        <TooltipContainer>
          <Components.Heading>PROPERTY TYPE</Components.Heading>
          <InfoTooltip
            text="To filter the plots based on the property type."
            tooltipStyle={{
              fontSize: "14px",
              left: "44px !important"
            }}
          />
        </TooltipContainer>
        <div style={{ margin: "0px 15px", marginTop: "20px" }}>
          <Components.AreaButton
            style={{ width: "80%" }}
            onClick={() => {
              if (propertyType === "all") {
                dispatch(setPropertyType(null));
              } else {
                dispatch(setPropertyType("all"));
              }
            }}
            active={propertyType === "all"}
          >
            ALL
          </Components.AreaButton>
          <Components.AreaButton
            style={{ width: "80%" }}
            onClick={() => {
              if (propertyType === "com") {
                dispatch(setPropertyType(null));
              } else {
                dispatch(setPropertyType("com"));
              }
            }}
            active={propertyType === "com"}
          >
            COMMERCIAL
          </Components.AreaButton>
          <Components.AreaButton
            style={{ width: "80%" }}
            onClick={() => {
              if (propertyType === "res") {
                dispatch(setPropertyType(null));
              } else {
                dispatch(setPropertyType("res"));
              }
            }}
            active={propertyType === "res"}
          >
            RESIDENTIAL
          </Components.AreaButton>
        </div>
      </Components.GradientContainer>

      <Components.GradientContainer style={{ marginBottom: "100px" }}>
        <TooltipContainer>
          <Components.Heading>LAND ZONES</Components.Heading>
          <InfoTooltip
            text="To filter the plots based on the land zones."
            tooltipStyle={{
              fontSize: "14px",
              left: "58px !important"
            }}
          />
        </TooltipContainer>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            flexDirection: "column",
            margin: "0px 15px"
          }}
        >
          <Components.AreaButton
            style={{
              width: "80%",
              margin: "10px auto 0px auto",
              backgroundColor: zoneType === "blue" ? "rgb(19, 64, 116)" : ""
            }}
            onClick={() => {
              if (zoneType === "blue") {
                dispatch(setZoneType(null));
              } else {
                dispatch(setZoneType("blue"));
                dispatch(setPropertyType("all"));
              }
            }}
            active={zoneType === "blue"}
          >
            BLUE | $500 - $1,000
          </Components.AreaButton>
          <Components.AreaButton
            style={{
              width: "80%",
              margin: "10px auto 0px auto",
              backgroundColor: zoneType === "green" ? "rgb(81, 152, 114)" : ""
            }}
            onClick={() => {
              if (zoneType === "green") {
                dispatch(setZoneType(null));
              } else {
                dispatch(setZoneType("green"));
                dispatch(setPropertyType("all"));
              }
            }}
            active={zoneType === "green"}
          >
            GREEN | $2,000 - $4,000
          </Components.AreaButton>
          <Components.AreaButton
            style={{
              width: "80%",
              margin: "10px auto 0px auto",
              backgroundColor: zoneType === "yellow" ? "rgb(249, 220, 92)" : ""
            }}
            onClick={() => {
              if (zoneType === "yellow") {
                dispatch(setZoneType(null));
              } else {
                dispatch(setZoneType("yellow"));
                dispatch(setPropertyType("all"));
              }
            }}
            active={zoneType === "yellow"}
          >
            YELLOW | $4,500 - $9,000
          </Components.AreaButton>
          <Components.AreaButton
            style={{
              width: "80%",
              margin: "10px auto 0px auto",
              backgroundColor: zoneType === "red" ? "rgba(202, 60, 37)" : ""
            }}
            onClick={() => {
              if (zoneType === "red") {
                dispatch(setZoneType(null));
              } else {
                dispatch(setZoneType("red"));
                dispatch(setPropertyType("all"));
              }
            }}
            active={zoneType === "red"}
          >
            RED | $10,000 - $20,000
          </Components.AreaButton>
        </div>
      </Components.GradientContainer>

      <div
        style={{
          display: "flex",
          width: "230px",
          margin: "15px auto"
        }}
      >
        <Components.Heading>VIEW LAND</Components.Heading>
        <div style={{ marginLeft: "auto" }}>
          <Switch
            option1={"ON"}
            option2={"OFF"}
            currentTab={availabilityStatus ? 0 : 1}
            onChange={changeAvailabilityStatus}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "230px",
          margin: "15px auto"
        }}
      >
        <Components.Heading>
          {themeStatus ? "LIGHT MODE" : "DARK MODE"}
        </Components.Heading>
        <div style={{ marginLeft: "auto" }}>
          <Switch
            option1={<img src={LightModeLogo} />}
            option2={<img src={DarkModeLogo} />}
            currentTab={themeStatus ? 0 : 1}
            onChange={() => {
              changeThemeStatus();
              switchTheme();
            }}
          />
        </div>
      </div>
      <br />
      <br />
      <div
        style={{
          display: "flex",
          width: "fit-content",
          margin: "auto"
        }}
      >
        Contact us:{" "}
        <a
          href="mailto:info@exarta.com"
          style={{
            color: "#bc679c",
            paddingLeft: "5px"
          }}
        >
          {" "}
          info@exarta.com
        </a>
      </div>
      <br />
      <br />
    </Components.Container>
  );
};

export default DesktopOptionsBar;
