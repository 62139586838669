import * as types from "../../types";

type Box = {
  top: number;
  left: number;
};

export const setSelectedPlot = (value: Box | null) => {
  return {
    type: types.SET_SELECTED_PLOT,
    payload: value,
  };
};

export const setSelectablePlots = (value: Box[] | []) => {
  return {
    type: types.SET_SELECTABLE_PLOTS,
    payload: value,
  };
};

export const setPropertyType = (value: string | null) => {
  return {
    type: types.SET_PROPERTY_TYPE,
    payload: value,
  };
};

export const setBoughtPlots = (value: []) => {
  return {
    type: types.SET_BOUGHT_PLOTS,
    payload: value,
  }
}

export const setZoneType = (value: string | null) => {
  return {
    type: types.SET_ZONE_TYPE,
    payload: value,
  };
};

export const setZoomToElement = (value: boolean) => {
  return {
    type: types.SET_ZOOM_TO_ELEMENT,
    payload: value,
  };
};
