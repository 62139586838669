import { styled } from "@mui/material/styles";

export const MainContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "600px",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "30px 0px",
  borderRadius: "20px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

export const CloseButton = styled("div")(({ theme }) => ({
  position: "absolute",
  left: "-10px",
  top: "-10px",
  height: "40px",
  width: "40px",
  borderRadius: "100%",
  borderColor: theme.palette.background.paper,
  border: "solid 2px",
  backgroundColor: theme.palette.primary.main,
  padding: "7px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  fontWeight: "500",

  [theme.breakpoints.down("sm")]: {
    height: "35px",
    width: "35px",
  },
}));

export const Heading = styled("div")(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "500",
  color: theme.palette.background.paper,
  textAlign: "center",
  marginBottom: "20px",
}));

export const SubHeading = styled("div")(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.background.paper,
  textAlign: "center",
  fontWeight: "500",

  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
}));

export const HorizontalDivider = styled("div", {
  shouldForwardProp: (prop) => prop !== "opacity",
})<{
  opacity: number;
}>(({ theme, opacity }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  opacity,
}));

export const BorderButton = styled("div")(({ theme }) => ({
  width: "220px",
  borderRadius: "10px",
  border: "solid 1px " + theme.palette.primary.dark,
  color: theme.palette.primary.dark,
  margin: "10px 0px",
  marginBottom: "0px",

  padding: "7px 50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "17px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.paper,
  },
  [theme.breakpoints.down("sm")]: {
    marginRight: "0px !important",
  },
}));

export const ItemBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{
  isMobile: Boolean;
}>(({ theme, isMobile }) => ({
  display: "flex",
  width: isMobile ? "90%" : "90%",
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "20px",
  flexWrap: "wrap",
  alignItems: "center",
  padding: "20px 30px",
  borderRadius: "29px",

  background: theme.palette.background.itemBoxColor,
}));

export const ButtonBoxStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));
