import { FC, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import axios from "axios";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useDispatch, useSelector } from "react-redux";
import * as Component from "../../components";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setLoadingModalStatus,
} from "../../../../redux/actions/modalActions";
import TextField from '@mui/material/TextField';
import { BASE_URL } from "../../../../utils/api";

const Container = styled("div")(({ theme }) => ({
  width: "1000px",
  backgroundColor: alpha(theme.palette.primary.dark, 0.1),
  borderRadius: "50px",
  margin: "auto",
  paddingBottom: "40px",
  marginBottom: "100px",
  marginTop: "30px",

  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 40px)",
    marginTop: "5px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 40px)",
    backgroundColor: theme.palette.primary.main,
  },
}));

const BackDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
  padding: "20px 30px",

  [theme.breakpoints.down("sm")]: {
    padding: "20px 10px",
  },
}));

const BackText = styled("h5")(({ theme }) => ({
  fontSize: "19px",
  lineHeight: "15px",
  textTransform: "uppercase",
  fontWeight: "500",
}));

const FileLabel = styled("label")(({ theme }) => ({
  height: "100%",
  width: "100%",
  backgroundColor: alpha("#000", 0.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  fontWeight: "700",
  fontSize: "20px",
  opacity: "0",

  ":hover": {
    opacity: "1",
  },
}));

const Label = styled("h1")(({ theme }) => ({
  fontSize: "17px",
  fontWeight: "500",
  textTransform: "uppercase",
}));

const Column = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  margin: "0px 50px",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },

  [theme.breakpoints.down("sm")]: {
    margin: "0px",
  },
}));

const InputContainer = styled("div")(({ theme }) => ({
  textAlign: "left",
  width: "100%",
  flex: "1",
  padding: "0px 30px",
  marginTop: "30px",
  marginBottom: "20px",

  [theme.breakpoints.down("sm")]: {
    padding: "0px 10px",
  },
  
}));

const Input = styled("input")(({ theme }) => ({
  border: "none",
  borderBottom: "1px solid #665a68",
  background: "transparent",
  width: "100%",
  outline: "none",
  color: theme.palette.background.paper,
  fontSize: "20px",
  placeholderTextColor: "red",
  textIndent: "10px",
  padding: "8px 0px",

  "&::-webkit-input-placeholder": {
    color: "theme.palette.background.paper",
    opacity: "0.7",
    fontWeight: "500",
  },
  '-webkit-user-select': 'text !important' /* Chrome, Opera, Safari */,
  '-moz-user-select': 'text !important' /* Firefox 2+ */,
  '-ms-user-select': 'text !important' /* IE 10+ */,
  'user-select': 'text !important' /* Standard syntax */,
}));

const EditModal: FC<{
  setEditModalStatus: (status: boolean) => void;
  userDetails: any;
  getUserDetails: () => void;
}> = ({ setEditModalStatus, userDetails, getUserDetails }) => {
  const [previewCoverImage, setPreviewCoverImage] = useState<string>("");
  const [previewProfileImage, setPreviewProfileImage] = useState<string>("");
  const [selectedCoverImage, setSelectedCoverImage] = useState<any>(null);
  const [selectedProfileImage, setSelectedProfileImage] = useState<any>(null);

  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState(userDetails?.name.split("###")[0]);
  const [lastName, setLastName] = useState(userDetails?.name.split("###")[1]);

  const [bio, setBio] = useState(userDetails?.bio);
  const [twitterHandle, setTwitterHandle] = useState(
    userDetails?.twitterHandle
  );
  const [instagramHandle, setInstagramHandle] = useState(
    userDetails?.instagramHandle
  );
  const [websiteUrl, setWebsiteUrl] = useState(userDetails?.websiteUrl);

  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChange = async (e: any, type: string) => {
    if ((e.target as HTMLInputElement).files && e.target.files[0]) {
      if (type === "profile") {
        const base64 = await convertBase64(e.target.files[0]);
        setSelectedProfileImage(base64);
      } else {
        const base64 = await convertBase64(e.target.files[0]);
        setSelectedCoverImage(base64);
      }

      let reader = new FileReader();

      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target?.result as string;
        image.onload = function () {
          if (type === "profile") {
            setPreviewProfileImage(e.target?.result as string);
          } else {
            setPreviewCoverImage(e.target?.result as string);
          }
        };
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSubmit = async () => {
    dispatch(setLoadingModalStatus(true));

    const formData = new FormData();

    if (selectedProfileImage) {
      const profileImg = selectedProfileImage.toString("base64");
      const profileImgBuffer = Buffer.from(profileImg);
      const profileImgBlob = new Blob([profileImgBuffer]);
      formData.append("profileImg", profileImgBlob);
    } else {
      formData.append("profileImgUrl", previewProfileImage);
    }

    if (selectedCoverImage) {
      const coverImg = selectedCoverImage.toString("base64");
      const coverImgBuffer = Buffer.from(coverImg);
      const coverImgBlob = new Blob([coverImgBuffer]);
      formData.append("coverImg", coverImgBlob);
    } else {
      formData.append("coverImg", previewCoverImage);
    }

    if (firstName && lastName) {
      formData.append("name", firstName + "###" + lastName);
    }
    if (bio) {
      formData.append("bio", bio);
    }
    if (twitterHandle) {
      formData.append("twitterHandle", twitterHandle);
    }
    if (instagramHandle) {
      formData.append("instagramHandle", instagramHandle);
    }
    if (websiteUrl) {
      formData.append("websiteUrl", websiteUrl);
    }

    formData.append("token", authToken);

    axios
      .post(`${BASE_URL}/user/updateUser`, formData, {
        headers: {
          "Content-Type": `multipart/form-data;`,
        },
      })
      .then((res) => {
        dispatch(setLoadingModalStatus(false));
        if (res?.data?.status) {
          dispatch(
            setAlertModalMsg({
              text: res?.data?.message,
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: 'Alert',
            })
          );
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
          setEditModalStatus(false);
          getUserDetails();
        } else {
          dispatch(
            setAlertModalMsg({
              text: res?.data?.message,
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: 'Alert',
            })
          );
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
        }
      })
      .catch((e) => {
        dispatch(setLoadingModalStatus(false));
        dispatch(
          setAlertModalMsg({
            text: "Some error occurred, Try again.",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: 'Alert',
          })
        );
        dispatch(setCheckoutModalStatus(false));
        dispatch(setAlertModalStatus(true));
      });
  };

  useEffect(() => {
    setPreviewCoverImage(userDetails?.coverPhoto);
    setPreviewProfileImage(userDetails?.image);
  }, []);

  return (
    <Component.MainContainer>
      <BackDiv onClick={() => setEditModalStatus(false)}>
        <KeyboardArrowLeftIcon
          style={{ height: "30px", width: "30px", cursor: "pointer" }}
        />
        <BackText>Back</BackText>
      </BackDiv>

      <Container>
        <Component.CoverPhoto
          coverPhoto={previewCoverImage}
          style={{ margin: "0px", width: "100%" }}
        >
          <input
            id="cover"
            onClick={(e) => e.stopPropagation()}
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={(e) => {
              onChange(e, "cover");
            }}
          />

          <FileLabel onClick={(e) => e.stopPropagation()} htmlFor="cover">
            <span style={{ fontSize: "40px" }}>Upload</span>
            <UploadFileIcon
              style={{ height: "50px", width: "50px", marginLeft: "5px" }}
            />
          </FileLabel>
        </Component.CoverPhoto>

        <Component.ProfilePhoto profilePhoto={previewProfileImage}>
          <input
            id="profile"
            onClick={(e) => e.stopPropagation()}
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={(e) => {
              onChange(e, "profile");
            }}
          />

          <FileLabel onClick={(e) => e.stopPropagation()} htmlFor="profile">
            <span style={{ fontSize: "15px" }}>Upload</span>
            <UploadFileIcon style={{ height: "20px", width: "20px" }} />
          </FileLabel>
        </Component.ProfilePhoto>

        <Column>
          <InputContainer>
            <Label>First Name</Label>
            {/* <TextField
             id="standard-basic" 
             label="Maha" 
             variant="standard" 
             onChange={e => setFirstName(e.target.value)}
             value={firstName}
            /> */}
            <Input
              placeholder="Maha"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </InputContainer>
          <InputContainer>
            <Label>Last Name</Label>
            <Input
              placeholder="Khan"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </InputContainer>
        </Column>

        <Column>
          <InputContainer>
            <Label>Bio</Label>
            <Input
              placeholder="Write your bio here..."
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
          </InputContainer>
        </Column>

        <Column>
          <InputContainer>
            <Label>Twitter</Label>
            <Input
              placeholder="https://twitter.com/"
              value={twitterHandle}
              onChange={(e) => setTwitterHandle(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <Label>Instagram</Label>
            <Input
              placeholder="https://instagram.com/"
              value={instagramHandle}
              onChange={(e) => setInstagramHandle(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <Label>Website</Label>
            <Input
              placeholder="add your website"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
            />
          </InputContainer>
        </Column>

        <Component.EditButton onClick={onSubmit} style={{ marginTop: "50px" }}>
          Save & Continue
        </Component.EditButton>
      </Container>
    </Component.MainContainer>
  );
};

export default EditModal;
