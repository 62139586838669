/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { Twitter, Instagram, Language } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import axios from "axios";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import * as Component from "./components";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setLoadingModalStatus
} from "../../redux/actions/modalActions";
import { BASE_URL } from "../../utils/api";
import PanelComponent from "./components/Panel";
import HeaderComponent from "./components/Header";
import EditModal from "./components/EditModal";
import { CircularProgress } from "@mui/material";

const Profile: FC = () => {
  const [editable, setEditable] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [editModalStatus, setEditModalStatus] = useState<boolean>(false);
  const [isPrivate, setIsPrivate] = useState<boolean>(true);
  const [profilePrivateStatus, setProfilePrivateStatus] =
    useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { userId } = useParams();

  const dispatch = useDispatch();

  const currentUserId = useSelector((state: any) => {
    return state.userReducer.uid;
  });
  const currentPrivacyStatus = useSelector((state: any) => {
    return state.userReducer.isPrivate;
  });
  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });
  useEffect(() => {
    let privacy;
    if (!currentPrivacyStatus) privacy = false;
    else privacy = currentPrivacyStatus;
    setIsPrivate(privacy);
  }, []);

  const checkCurrentUser = () => {
    if (userId === currentUserId) {
      setEditable(true);
    } else {
      setEditable(false);
      setSelectedTab(0);
    }
  };
  const handleProfilePrivacy = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      console.log("authToken", authToken);
      const response = await axios.put(
        `${BASE_URL}/user/updateUserProfilePrivacy`,
        {
          token: authToken
        }
      );
      // dispatch get user details
      setIsPrivate(response.data.currentPrivacy);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      //@ts-ignore
      if (e?.response?.data?.statusCode === 403) {
        dispatch(
          setAlertModalMsg({
            text: "Item Added to Cart.",
            buttonText: "OK",
            onPress: () => dispatch(setAlertModalStatus(false)),
            heading: "Alert"
          })
        );
      } else {
        dispatch(
          setAlertModalMsg({
            text: "You need to login first.",
            buttonText: "Login",
            onPress: () => {
              dispatch(setAlertModalStatus(false));
              navigate("/login");
            },
            heading: "Alert"
          })
        );
      }
      dispatch(setLoadingModalStatus(false));
      dispatch(setAlertModalStatus(true));
    }
  };
  const getUserDetails = async () => {
    try {
      let res = await axios.put(`${BASE_URL}/user/getUserFromId`, {
        id: userId
      });
      setUserDetails(res.data.user);
      setProfilePrivateStatus(res.data.user.isPrivate);
    } catch (e) {
      dispatch(
        setAlertModalMsg({
          text: "Some error occurred, Try again.",
          buttonText: "OK",
          onPress: () => dispatch(setAlertModalStatus(false)),
          heading: "Alert"
        })
      );
      dispatch(setCheckoutModalStatus(false));
      dispatch(setAlertModalStatus(true));
    }
    dispatch(setLoadingModalStatus(false));
    checkCurrentUser();
  };

  useEffect(() => {
    if (!userId) {
      navigate("/");
    }
    getUserDetails();
  }, [userId]);

  useEffect(() => {
    checkCurrentUser();
  }, [currentUserId]);

  return (
    <Component.MainContainer>
      <HeaderComponent />

      <Component.CoverPhoto coverPhoto={userDetails?.coverPhoto} />

      <Component.ProfilePhoto profilePhoto={userDetails?.image} />

      <Component.UserNameText>
        {editable || !profilePrivateStatus
          ? userDetails?.name.replaceAll("###", " ")
          : "Exarta User"}
      </Component.UserNameText>
      <Component.EmailText>
        {editable || !profilePrivateStatus ? userDetails?.email : userId}
      </Component.EmailText>
      <Component.BioText>
        {editable || !profilePrivateStatus
          ? userDetails?.bio
          : "Hey there! I am using Exarta Landsales."}
      </Component.BioText>

      <Component.Socials>
        <Twitter
          onClick={() => {
            if (profilePrivateStatus || !userDetails?.twitterHandle) {
              return;
            }
            window.open(
              `https://twitter.com/${userDetails?.twitterHandle}`,
              "_blank"
            );
          }}
          sx={{
            margin: "0px 20px",
            height: "40px",
            width: "40px",
            cursor: "pointer",
            opacity: userDetails?.twitterHandle ? "1" : "0.5"
          }}
          titleAccess={userDetails?.twitterHandle ? "" : "Not Provided."}
        />
        <Instagram
          onClick={() => {
            if (profilePrivateStatus || !userDetails?.instagramHandle) {
              return;
            }
            window.open(
              `https://www.instagram.com/${userDetails?.instagramHandle}`,
              "_blank"
            );
          }}
          sx={{
            margin: "0px 20px",
            height: "40px",
            width: "40px",
            cursor: "pointer",
            opacity: userDetails?.instagramHandle ? "1" : "0.5"
          }}
          titleAccess={userDetails?.instagramHandle ? "" : "Not Provided."}
        />
        <Language
          onClick={() => {
            if (profilePrivateStatus || !userDetails?.websiteUrl) {
              return;
            }
            window.open(userDetails?.websiteUrl, "_blank");
          }}
          sx={{
            margin: "0px 20px",
            height: "40px",
            width: "40px",
            cursor: "pointer",
            opacity: userDetails?.websiteUrl ? "1" : "0.5"
          }}
          titleAccess={userDetails?.websiteUrl ? "" : "Not Provided."}
        />
      </Component.Socials>
      {editable ? (
        <Component.EditProfilePrivacyButton
          onClick={() => {
            handleProfilePrivacy();
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "125px",
              height: "25px"
            }}
          >
            {isLoading ? (
              <CircularProgress
                style={{
                  color: "white",
                  width: "20px",
                  height: "20px"
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  justifyContent: "center"
                }}
              >
                {" "}
                {isPrivate ? (
                  <LockIcon
                    fontSize="medium"
                    style={{
                      paddingBottom: "1px"
                    }}
                  />
                ) : (
                  <LockOpenIcon fontSize="small" />
                )}
                <span>{isPrivate ? "Private" : "Public"}</span>
              </div>
            )}
          </div>
        </Component.EditProfilePrivacyButton>
      ) : null}
      {editable ? (
        <Component.EditButton
          onClick={() => {
            setEditModalStatus(!editModalStatus);
          }}
        >
          Edit Profile
        </Component.EditButton>
      ) : null}

      <Component.Tabs>
        <Component.Tab
          isActive={selectedTab === 0}
          onClick={() => setSelectedTab(0)}
        >
          {editable ? "My Land" : "Owned Land"}
        </Component.Tab>
        {editable ? (
          <Component.Tab
            isActive={selectedTab === 1}
            onClick={() => setSelectedTab(1)}
          >
            Favorite
          </Component.Tab>
        ) : null}
      </Component.Tabs>

      <PanelComponent selectedTab={selectedTab} userDetails={userDetails} />

      <Modal
        open={editModalStatus}
        onClose={() => {
          dispatch(setEditModalStatus(false));
        }}
        style={{ zIndex: "1000000" }}
      >
        <>
          <EditModal
            setEditModalStatus={setEditModalStatus}
            userDetails={userDetails}
            getUserDetails={getUserDetails}
          />
        </>
      </Modal>
    </Component.MainContainer>
  );
};

export default Profile;
