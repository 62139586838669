import { styled, alpha } from "@mui/material/styles";

export const Container = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "300px",
  backgroundColor: theme.palette.primary.main,
  overflowY: "auto",
  boxShadow: "0px 0px 10px" + theme.palette.primary.main,
  zIndex: "100",

  "::-webkit-scrollbar": {
    width: "7px",
  },

  "::-webkit-scrollbar-track": {},
  "::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "10px",
  },
}));

export const LogoElement = styled("div")(({ theme }) => ({
  width: "100%",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "30px auto",
  marginBottom: "40px",
  cursor: "pointer",
}));

export const LogoImage = styled("img")(({ theme }) => ({
  height: "28px",
}));

export const LogoName = styled("div")(({ theme }) => ({
  fontSize: "20px",
  marginLeft: "10px",
  marginRight: "15px",
}));

export const GradientContainer = styled("div")(({ theme }) => ({
  margin: "20px auto",
  marginBottom: "30px",
  height: "200px",
  width: "250px",
  backgroundImage: `linear-gradient(${theme.palette.primary.light}, ${theme.palette.primary.main})`,
  borderRadius: "20px",
  paddingTop: "20px",
}));

export const Heading = styled("div")(({ theme }) => ({
  fontSize: "15px",
  color: theme.palette.background.paper,
  textAlign: "center",
  fontWeight: "500",
}));

export const Input = styled("input")(({ theme }) => ({
  fontSize: "15px",
  height: "30px",
  width: "100%",
  color: alpha(theme.palette.background.paper, 1),
  outline: "none",
  border: "none",
  backgroundColor: "transparent",
  fontWeight: "400",
  borderColor: alpha(theme.palette.background.paper, 0.5),
  borderBottom: "solid 1px " + alpha(theme.palette.background.paper, 0.5),
  padding: "0px 15px",
  marginTop: "25px",
  paddingBottom: "10px",

  "::placeholder": {
    color: alpha(theme.palette.background.paper, 0.3),
  },
}));

export const VerticalDivider = styled("div")(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.3),
  width: "1px",
  height: "30px",
  marginTop: "30px",
}));

export const BorderButton = styled("div")(({ theme }) => ({
  height: "30px",
  width: "fit-content",
  borderRadius: "30px",
  border: "solid 1px " + theme.palette.primary.dark,
  color: theme.palette.primary.dark,
  margin: "25px auto",
  padding: "0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "10px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.paper,
  },
}));

export const AreaButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ theme, active }) => ({
  height: "30px",
  width: "50px",
  borderRadius: "10px",
  border: "solid 1px",
  margin: "10px auto",
  padding: "0px 5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "11px",
  cursor: "pointer",
  opacity: "0.7",
  color: theme.palette.text.primary,
  fontWeight: "500",

  "&:hover": {
    border: "solid 1px " + theme.palette.primary.dark,
    color: theme.palette.primary.dark,
  },
}));
