import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import themeReducer from "../reducers/themeReducer";
import authReducer from "../reducers/authReducer";
import cartReducer from "../reducers/cartReducer";
import userReducer from "../reducers/userReducer";
import modalReducer from "../reducers/modalReducer";
import plotReducer from "../reducers/plotReducer";

// ***Redux with persistance
const persistConfig = {
  key: "logs-exarta",
  storage: storage,
  whitelist: ["themeReducer", "authReducer", "cartReducer"]
};

const Reducer = persistReducer(
  persistConfig,
  combineReducers({
    themeReducer,
    authReducer,
    cartReducer,
    userReducer,
    modalReducer,
    plotReducer
  })
);

// ***Redux without persistance
// const Reducer = combineReducers({ booksReducer, articlesReducer });

//******************************************************************************************

// ***For Development:
// const middleware = applyMiddleware(thunk, logger);
const middleware = applyMiddleware(thunk);
const store = createStore(Reducer, middleware);

// ***For Production:
// const store = createStore(Reducer);

const persistor = persistStore(store);

export { persistor, store };
