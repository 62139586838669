import { styled, alpha } from "@mui/material/styles";

export const MainContainer = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100vw",
  overflowY: "auto",
  overflowX: "hidden",
  background: theme.palette.primary.main,

  "::-webkit-scrollbar": {
    width: "7px"
  },

  "::-webkit-scrollbar-track": {},
  "::-webkit-scrollbar-thumb": {
    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
    borderRadius: "10px"
  }
}));

export const CoverPhoto = styled("div")<{ coverPhoto: string }>(
  ({ theme, coverPhoto }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "1000px",
    background: theme.palette.background.itemBoxColor,
    height: "350px",
    alignSelf: "center",
    borderRadius: "50px",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
    marginTop: "10px",
    backgroundImage: `url(${coverPhoto})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 40px)"
    },

    [theme.breakpoints.down("sm")]: {
      height: "200px"
    }
  })
);

export const ProfilePhoto = styled("div")<{ profilePhoto: string }>(
  ({ theme, profilePhoto }) => ({
    height: "150px",
    width: "150px",
    borderRadius: "75px",
    border: "5px solid" + theme.palette.primary.main,
    background: theme.palette.background.itemBoxColor,
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    margin: "auto",
    marginTop: "-75px",
    backgroundImage: `url(${profilePhoto})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  })
);

export const UserNameText = styled("div")(({ theme }) => ({
  fontSize: "30px",
  color: theme.palette.primary.dark,
  margin: "0px 20px",
  marginTop: "10px",
  textTransform: "uppercase",
  fontWeight: "bold",
  textAlign: "center",
  wordBreak: "break-all"
}));

export const EmailText = styled("div")(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.primary.dark,
  margin: "0px 20px",
  marginTop: "5px",
  fontWeight: "bold",
  textAlign: "center",
  wordBreak: "break-all"
}));

export const BioText = styled("div")(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.text.primary,
  margin: "10px auto",
  fontWeight: "400",
  textAlign: "center",
  wordBreak: "break-all",
  maxWidth: "400px"
}));

export const Socials = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignSelf: "center",
  alignItems: "center",
  margin: "15px auto",
  width: "fit-content",
  padding: "10px"
}));

export const Tabs = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignSelf: "center",
  alignItems: "center",
  margin: "auto",
  width: "fit-content"
}));

export const Tab = styled("h2")<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    fontSize: "18px",
    color: isActive ? theme.palette.primary.dark : theme.palette.text.primary,
    borderBottom: "1px solid",
    borderColor: isActive
      ? theme.palette.primary.dark
      : theme.palette.text.primary,
    padding: "15px",
    fontWeight: "700",
    textTransform: "uppercase",
    cursor: "pointer"
  })
);
export const EditProfilePrivacyButton = styled("div")(({ theme }) => ({
  width: "fit-content",
  alignSelf: "center",
  justifyContent: "center",
  borderRadius: "12px",
  padding: "8px 10px",
  margin: "10px auto",
  marginBottom: "20px",
  fontSize: "18px",
  fontWeight: "500",
  cursor: "pointer",
  textAlign: "center",
  border: "solid 1px " + theme.palette.primary.dark,
  color: theme.palette.primary.dark,

  ":hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.primary
  }
}));
export const EditButton = styled("div")(({ theme }) => ({
  width: "300px",
  borderRadius: "30px",
  padding: "7px 21px",
  margin: "10px auto",
  marginBottom: "20px",
  fontSize: "18px",
  fontWeight: "500",
  cursor: "pointer",
  textAlign: "center",
  border: "solid 1px " + theme.palette.primary.dark,
  color: theme.palette.primary.dark,

  ":hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.primary
  }
}));
