/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from "@mui/material/styles";
import { FC, useEffect } from "react";
import DesktopOptionsBar from "./components/DesktopOptionsBar";
import RightSideBar from "./components/RightSideBar";
import MobileMap from "./components/MobileMap";
import Modal from "@mui/material/Modal";
import CheckoutModal from "./components/CheckoutModal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileTopNavBar from "./components/MobileTopNavBar";
import MobileOptionsBar from "./components/MobileOptionsBar";
import DesktopMap from "./components/DesktopMap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../utils/api";
import { SET_BOUGHT_PLOTS, SET_USER_DETAILS } from "../../redux/types";
import { setCheckoutModalStatus } from "../../redux/actions/modalActions";
import ProfileButton from "./components/ProfileButton";
import CartButton from "./components/CartButton";
import { setPropertyType } from "../../redux/actions/plotActions";

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
}));

const Home: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });

  const checkoutModalStatus = useSelector((state: any) => {
    return state.modalReducer.checkoutModalStatus;
  });

  useEffect(() => {
    getUserDetails().then((response) => {
      dispatch({ type: SET_USER_DETAILS, payload: response });
    });
  }, []);

  const getUserDetails = async () => {
    try {
      const request = await axios.post(`${BASE_URL}/user/getUser`, {
        token: authToken,
      });
      return request.data.user;
    } catch (error) {
      console.log(error);
    }
  };

  const getBoughtPlots = async () => {
    const res = await axios.get(`${BASE_URL}/user/getBoughtPlots`);
    let plots = {};
    res.data?.plots?.forEach((plot) => {
      plots = { ...plots, [plot.coordinate]: plot };
    });
    dispatch({ type: SET_BOUGHT_PLOTS, payload: plots });
  };

  useEffect(() => {
    getBoughtPlots();
    const intervalId = setInterval(async () => {
      getBoughtPlots();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    dispatch(setPropertyType("all"));
  }, []);

  return (
    <MainContainer style={{ flexDirection: isMobile ? "column" : "row" }}>
      {isMobile ? <MobileTopNavBar /> : null}

      {!isMobile && (
        <>
          <ProfileButton />
          <CartButton />
        </>
      )}

      {isMobile ? <MobileOptionsBar /> : <DesktopOptionsBar />}

      <RightSideBar />

      {isMobile ? <MobileMap /> : <DesktopMap />}

      <Modal
        open={checkoutModalStatus}
        onClose={() => {
          dispatch(setCheckoutModalStatus(false));
        }}
        style={{ zIndex: "1000000" }}
      >
        <>
          <CheckoutModal />
        </>
      </Modal>
    </MainContainer>
  );
};

export default Home;
