/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAuth,
  clearUser,
} from "../../../../redux/actions/authActions/index";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { ClickAwayListener } from "@mui/material";

const MainContainer = styled("div")(({ theme }) => ({
  borderRadius: "25px",
  width: "45px",
  height: "45px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "20px",
  right: "50px",
  zIndex: "100000",
  background: theme.palette.primary.main,
  cursor: "pointer",
  overflow: "hidden",

  [theme.breakpoints.down("md")]: {
    height: "40px",
    width: "40px",
    top: "13px",
    right: "15px",
  },
}));

const ProfileButton: FC = () => {
  const [active, setActive] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const firebase = useSelector((state: any) => {
    return state.authReducer.firebaseObj;
  });
  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });
  const userId = useSelector((state: any) => {
    return state.userReducer.uid;
  });
  const image = useSelector((state: any) => {
    return state.userReducer.image;
  });

  useEffect(() => {
    if (authToken) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [authToken]);

  const getLoggedInToolTip = () => {
    return (
      <div
        style={{
          fontSize: "19px",
          padding: "0px",
          cursor: "pointer",
          color: theme.palette.text.primary,
          width: "190px",
        }}
      >
        <div
          onClick={async () => {
            navigate(`/profile/${userId}`);
          }}
          style={{
            borderRadius: "5px",
            padding: "5px 10px",
            margin: "5px 0px",
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            lineHeight: "18px",
          }}
        >
          <PersonIcon
            style={{ height: "25px", width: "25px", marginRight: "10px" }}
          />
          {active ? "Profile" : "Login"}
        </div>
        <div
          style={{
            height: "1px",
            width: "95%",
            margin: "0px auto",
            opacity: "0.5",
            backgroundColor: theme.palette.text.primary,
          }}
        />
        <div
          onClick={async () => {
            dispatch(clearAuth());
            dispatch(clearUser());
            const auth = getAuth(firebase);
            await signOut(auth);
          }}
          style={{
            borderRadius: "5px",
            padding: "5px 10px",
            margin: "5px 0px",
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            lineHeight: "18px",
          }}
        >
          <LogoutIcon
            style={{ height: "25px", width: "25px", marginRight: "10px" }}
          />
          Logout
        </div>
      </div>
    );
  };

  const getLoggedOutToolTip = () => {
    return (
      <div
        style={{
          fontSize: "19px",
          padding: "0px",
          cursor: "pointer",
          color: theme.palette.text.primary,
          width: "190px",
        }}
      >
        <div
          onClick={async () => {
            if (active) navigate(`/profile/${userId}`);
            else navigate("/login");
          }}
          style={{
            borderRadius: "5px",
            padding: "5px 10px",
            margin: "5px 0px",
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            lineHeight: "18px",
          }}
        >
          <PersonIcon
            style={{ height: "25px", width: "25px", marginRight: "10px" }}
          />
          Login
        </div>
      </div>
    );
  };

  return (
    <Tooltip
      title={
        active ? (
          <ClickAwayListener
            onClickAway={() => {
              setShowTooltip(false);
            }}
          >
            {getLoggedInToolTip()}
          </ClickAwayListener>
        ) : (
          <ClickAwayListener
            onClickAway={() => {
              setShowTooltip(false);
            }}
          >
            {getLoggedOutToolTip()}
          </ClickAwayListener>
        )
      }
      arrow={false}
      open={showTooltip}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: theme.palette.primary.main,
            borderRadius: "9px",
            "& .MuiTooltip-arrow": {
              color: alpha(theme.palette.text.primary, 1),
            },
            // boxShadow: "0px 0px 10px " + theme.palette.primary.main,
            border: "solid 2px " + alpha(theme.palette.text.primary, 0.5),
            display: "block",
            marginRight: "10px",
          },
        },
      }}
    >
      <MainContainer
        onClick={() => {
          setShowTooltip(true);
        }}
      >
        {active ? (
          <img
            src={image}
            style={{
              height: "100%",
              width: "100%",
              userSelect: "none",
            }}
          />
        ) : (
          <PersonIcon
            sx={{
              height: "30px",
              width: "30px",
            }}
          />
        )}
      </MainContainer>
    </Tooltip>
  );
};

export default ProfileButton;
