import { styled, alpha } from "@mui/material/styles";
import LikeLogo from "../../../../assets/like.svg";
import LikeWhiteLogo from "../../../../assets/likeWhite.svg";

export const MainContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "status"
})<{ status: boolean }>(({ theme, status }) => ({
  position: "fixed",
  top: "100px",
  right: "0px",
  width: "300px",
  height: `calc(100% - 115px)`,
  backgroundColor: theme.palette.primary.main,
  borderTopLeftRadius: "20px",
  borderBottomLeftRadius: "20px",
  zIndex: "1000",
  display: status ? "block" : "none",

  overflowY: "auto",
  overflowX: "hidden",

  boxShadow: "0px 0px 10px" + theme.palette.primary.main,

  "::-webkit-scrollbar": {
    width: "7px"
  },

  "::-webkit-scrollbar-track": {},
  "::-webkit-scrollbar-thumb": {
    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
    borderRadius: "10px"
  },

  [theme.breakpoints.down("sm")]: {
    top: "140px",
    height: `calc(100% - 255px)`
  }
}));

export const CloseButton = styled("div")(({ theme }) => ({
  position: "fixed",
  right: "280px",
  top: "90px",
  height: "30px",
  width: "30px",
  borderRadius: "100%",
  borderColor: theme.palette.background.paper,
  border: "solid 2px",
  backgroundColor: theme.palette.primary.main,
  padding: "7px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  fontWeight: "500",

  [theme.breakpoints.down("sm")]: {
    top: "130px"
  }
}));

export const GradientContainer = styled("div")(({ theme }) => ({
  margin: "30px",
  marginBottom: "30px",
  height: "200px",
  width: "240px",
  backgroundImage: `linear-gradient(${theme.palette.primary.light}, ${theme.palette.primary.main})`,
  borderRadius: "20px",
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
}));

export const Heading = styled("div")(({ theme }) => ({
  fontSize: "13px",
  color: theme.palette.background.paper,
  textAlign: "center"
}));

export const BorderButton = styled("div")(({ theme }) => ({
  height: "50px",
  borderRadius: "10px",
  border: "solid 1px " + theme.palette.primary.dark,
  color: theme.palette.primary.dark,
  margin: "10px auto",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  cursor: "pointer",
  opacity: "0.7",

  "&:hover": {
    border: "solid 1px " + theme.palette.primary.dark,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.dark
  }
}));
export const CustomLikeButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "active"
})<{ active: boolean }>(({ theme, active }) => ({
  height: "50px",
  borderRadius: "10px",
  border: "solid 1px " + theme.palette.primary.dark,
  color: active ? theme.palette.background.paper : theme.palette.primary.dark,
  margin: "10px auto",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  cursor: "pointer",
  opacity: "0.7",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",

  "&:hover": {
    color: theme.palette.background.paper,
  }
}));
export const LikeButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "active"
})<{
  active: boolean;
}>(({ theme, active }) => ({
  height: "50px",
  borderRadius: "10px",
  border: "solid 1px " + theme.palette.primary.dark,
  color: active ? theme.palette.background.paper : theme.palette.primary.dark,
  margin: "10px auto",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  cursor: "pointer",
  opacity: "0.7",
  backgroundImage: active ? `url(${LikeWhiteLogo})` : `url(${LikeLogo})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",

  "&:hover": {
    border: "solid 1px " + theme.palette.primary.dark,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.dark,
    backgroundImage: `url(${LikeWhiteLogo})`
  }
}));
