import * as types from "../../types";

export const addToCart = (coordinate: string) => {
  return {
    type: types.ADD_TO_CART,
    payload: coordinate,
  };
};

export const removeFromCart = (coordinate: string) => {
  return {
    type: types.REMOVE_FROM_CART,
    payload: coordinate,
  };
};

export const emptyCart = () => {
  return {
    type: types.EMPTY_CART,
    payload: null,
  };
};
