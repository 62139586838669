import ComPlots from "../pages/Home/components/Categories/comPlotAxis.json";
import ResPlots from "../pages/Home/components/Categories/resPlotAxis.json";
import RedPlots from "../pages/Home/components/Zones/redPlotsAxis.json";
import YellowPlots from "../pages/Home/components/Zones/yellowPlotsAxis.json";
import GreenPlots from "../pages/Home/components/Zones/greenPlotsAxis.json";
import BluePlots from "../pages/Home/components/Zones/bluePlotsAxis.json";

export const isValidEmail = (email) => {
  return email.match(
    //  eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const isValidWebsiteUrl = (url) => {
  if (
    (url.includes("http") || url.includes("https")) &&
    url.includes(".") &&
    !url.includes(" ")
  )
    return true;
  else return false;
};

export const isValidTwitterUrl = (twitterUrl) => {
  return twitterUrl.match(
    /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(#!\/)?[a-zA-Z0-9_]+$/i
  );
};

export const isValidInstagramAndTwitterHandle = (instagramHandle) => {
  const res = instagramHandle.match(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^`{|}~]/);
  if (res == null) return true;
  return false;
};

export const getPlotPrice = (plot: string) => {
  const BaseValueRes = 500;
  const BaseValueCom = 1000;
  const ZonePremiumBlue = 1;
  const ZonePremiumGreen = 4;
  const ZonePremiumYellow = 9;
  const ZonePremiumRed = 20;

  // const BaseValueRes = 1;
  // const BaseValueCom = 1;
  // const ZonePremiumBlue = 1;
  // const ZonePremiumGreen = 1;
  // const ZonePremiumYellow = 1;
  // const ZonePremiumRed = 1;

  let baseValue = 0;
  let zonePremium = 0;

  if (ComPlots.includes(plot)) {
    baseValue = BaseValueCom;
  } else if (ResPlots.includes(plot)) {
    baseValue = BaseValueRes;
  }

  if (RedPlots.includes(plot)) {
    zonePremium = ZonePremiumRed;
  } else if (YellowPlots.includes(plot)) {
    zonePremium = ZonePremiumYellow;
  } else if (GreenPlots.includes(plot)) {
    zonePremium = ZonePremiumGreen;
  } else if (BluePlots.includes(plot)) {
    zonePremium = ZonePremiumBlue;
  }

  const price = baseValue * zonePremium;

  return price;
};

// Format currency value and add commas
export const formatCurrency = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getPlotZone = (plot: string) => {
  let obj = {
    type: "",
    zone: ""
  };

  if (ComPlots.includes(plot)) {
    obj.type = "COMMERCIAL";
  } else if (ResPlots.includes(plot)) {
    obj.type = "RESIDENTIAL";
  }

  if (RedPlots.includes(plot)) {
    obj.zone = "RED";
  } else if (YellowPlots.includes(plot)) {
    obj.zone = "YELLOW";
  } else if (GreenPlots.includes(plot)) {
    obj.zone = "GREEN";
  } else if (BluePlots.includes(plot)) {
    obj.zone = "BLUE";
  }

  return obj;
};
