/* eslint-disable jsx-a11y/alt-text */
import { FC, useState } from "react";
import * as Components from "./components";
import {
  SelectionIcon,
  ZonesIcon,
  PropertyTypeIcon,
  DarkModeIcon,
  SelectionIconS,
  ZonesIconS,
  PropertyTypeIconS,
  DarkModeIconS,
  SelectionIconD,
  ZonesIconD,
  PropertyTypeIconD,
  DarkModeIconD,
  ArrowIconDark,
  ArrowIconLight,
  goToCoordinatesDarkMode,
  goToCoordinatesLightMode,
  goToCoordinates
} from "../../../../assets/index";

import { useThemeSwitch } from "../../../../hooks/switchTheme";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPropertyType,
  setSelectablePlots,
  setSelectedPlot,
  setZoneType,
  setZoomToElement
} from "../../../../redux/actions/plotActions";
import {
  setAlertModalMsg,
  setAlertModalStatus
} from "../../../../redux/actions/modalActions";
import AllPlotsAxis from "../Categories/allPlotAxis.json";

const MobileOptionsBar: FC = () => {
  const [, switchTheme] = useThemeSwitch();
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const [, setIsZoneTabOpen] = useState(false);
  const [, setIsPropertyTabOpen] = useState(false);
  const [isBottomNavOpen, setIsBottomNavOpen] = useState(true);
  const [isNavbarFade, setIsNavbarFade] = useState(false);
  const [xAxis, setXAxis] = useState<number>(-1);
  const [yAxis, setYAxis] = useState<number>(-1);
  const dispatch = useDispatch();

  const zoneType = useSelector((state: any) => {
    return state.plotReducer.selectedZoneType;
  });

  const propertyType = useSelector((state: any) => {
    return state.plotReducer.selectedPropertyType;
  });

  return (
    <div
      style={{
        position: "fixed",
        bottom: "5px",
        left: "0",
        right: "0",
        zIndex: 900,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        textAlign: "center"
      }}
    >
      <img
        //@ts-ignore
        src={theme?.palette?.mode === "dark" ? ArrowIconLight : ArrowIconDark}
        style={{
          height: "30px",
          width: "30px",
          marginLeft: "auto",
          marginRight: "auto",
          transform: isBottomNavOpen ? "rotate(0deg)" : "rotate(180deg)",
          WebkitFilter:
            //@ts-ignore
            theme?.palette?.mode === "dark"
              ? "drop-shadow(0px 0px 3px #000)"
              : "drop-shadow(0px 0px 3px #fff)",
          filter:
            //@ts-ignore
            theme?.palette?.mode === "dark"
              ? "drop-shadow(0px 0px 3px #000)"
              : "drop-shadow(0px 0px 3px #fff)",
          transition: "transform 0.3s linear"
        }}
        onClick={() => {
          if (isBottomNavOpen) {
            setIsNavbarFade(!isNavbarFade);
            setTimeout(() => {
              setIsBottomNavOpen(!isBottomNavOpen);
            }, 300);
          } else {
            setIsBottomNavOpen(!isBottomNavOpen);
            setTimeout(() => {
              setIsNavbarFade(!isNavbarFade);
            }, 300);
          }
        }}
      />

      {selectedTab === 1 && isBottomNavOpen ? (
        <Components.SubContainer
          style={{
            opacity: isNavbarFade ? "0" : "1",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100px",
            background: 'none'
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <Components.AreaButton
              style={{
                flex: 0.5,
                fontSize: "10px",
                backgroundColor: zoneType === "blue" ? "rgb(19, 64, 116)" : ""
              }}
              active={zoneType === "blue" ? true : false}
              onClick={() => {
                if (zoneType === "blue") {
                  dispatch(setZoneType(null));
                } else {
                  dispatch(setZoneType("blue"));
                  dispatch(setPropertyType("all"));
                }
              }}
            >
              BLUE | 500$ - 1000$
            </Components.AreaButton>

            <Components.AreaButton
              style={{
                flex: 0.5,
                fontSize: "10px",
                backgroundColor: zoneType === "green" ? "rgb(81, 152, 114)" : ""
              }}
              active={zoneType === "green" ? true : false}
              onClick={() => {
                if (zoneType === "green") {
                  dispatch(setZoneType(null));
                } else {
                  dispatch(setZoneType("green"));
                  dispatch(setPropertyType("all"));
                }
              }}
            >
              GREEN | 2000$ - 40000$
            </Components.AreaButton>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <Components.AreaButton
              style={{
                flex: 0.5,
                fontSize: "10px",
                backgroundColor:
                  zoneType === "yellow" ? "rgb(249, 220, 92)" : ""
              }}
              active={zoneType === "yellow" ? true : false}
              onClick={() => {
                if (zoneType === "yellow") {
                  dispatch(setZoneType(null));
                } else {
                  dispatch(setZoneType("yellow"));
                  dispatch(setPropertyType("all"));
                }
              }}
            >
              YELLOW | 4500$ - 9000$
            </Components.AreaButton>
            <Components.AreaButton
              style={{
                flex: 0.5,
                fontSize: "10px",
                backgroundColor: zoneType === "red" ? "rgba(202, 60, 37)" : ""
              }}
              active={zoneType === "red" ? true : false}
              onClick={() => {
                if (zoneType === "red") {
                  dispatch(setZoneType(null));
                } else {
                  dispatch(setZoneType("red"));
                  dispatch(setPropertyType("all"));
                }
              }}
            >
              RED | 10000$ - 20000$
            </Components.AreaButton>
          </div>
        </Components.SubContainer>
      ) : null}

      {selectedTab === 2 && isBottomNavOpen ? (
        <Components.SubContainer
          style={{
            opacity: isNavbarFade ? "0" : "1",
            background: 'none'
          }}
        >
          <Components.AreaButton
            style={{
              flex: 0.3,
              fontSize: "10px"
            }}
            active={propertyType === "all" ? true : false}
            onClick={() => {
              dispatch(setPropertyType("all"));
              dispatch(setZoneType(null));
            }}
          >
            ALL
          </Components.AreaButton>
          <Components.AreaButton
            style={{
              flex: 0.3,
              fontSize: "10px"
            }}
            active={propertyType === "com" ? true : false}
            onClick={() => {
              dispatch(setPropertyType("com"));
              dispatch(setZoneType(null));
            }}
          >
            COMMERCIAL
          </Components.AreaButton>
          <Components.AreaButton
            style={{
              flex: 0.3,
              fontSize: "10px"
            }}
            active={propertyType === "res" ? true : false}
            onClick={() => {
              dispatch(setPropertyType("res"));
              dispatch(setZoneType(null));
            }}
          >
            RESIDENTIAL
          </Components.AreaButton>
        </Components.SubContainer>
      ) : null}

      {selectedTab === 3 && isBottomNavOpen ? (
        <Components.SubContainer
          style={{
            opacity: isNavbarFade ? "0" : "1",
            height: "60px",
            overflow: "hidden"
          }}
        >
          <div style={{ flex: "0.5", padding: "0px 15px" }}>
            <Components.Input
              placeholder="x-axis"
              type="number"
              min={0}
              max={240}
              value={xAxis === -1 ? "" : xAxis}
              onChange={(e) => {
                if (
                  (parseInt(e.target.value) >= 0 &&
                    parseInt(e.target.value) <= 240) ||
                  e.target.value === ""
                ) {
                  setXAxis(parseInt(e.target.value));
                }
              }}
            />
          </div>
          <Components.VerticalDivider />
          <div style={{ flex: "0.5", padding: "0px 15px" }}>
            <Components.Input
              placeholder="y-axis"
              type="number"
              min={0}
              max={240}
              value={yAxis === -1 ? "" : yAxis}
              onChange={(e) => {
                if (
                  (parseInt(e.target.value) >= 0 &&
                    parseInt(e.target.value) <= 240) ||
                  e.target.value === ""
                ) {
                  setYAxis(parseInt(e.target.value));
                }
              }}
            />
          </div>
          <Components.BorderButton
            onClick={() => {
              if (yAxis !== -1 && xAxis !== -1) {
                let plot = xAxis.toString() + "," + yAxis.toString();
                if (AllPlotsAxis.includes(plot)) {
                  dispatch(setSelectedPlot({ top: yAxis, left: xAxis }));
                  dispatch(setZoomToElement(true));
                } else {
                  dispatch(
                    setAlertModalMsg({
                      text: "Invalid Coordinates",
                      buttonText: "OK",
                      onPress: () => dispatch(setAlertModalStatus(false)),
                      heading: "Alert"
                    })
                  );
                  dispatch(setAlertModalStatus(true));
                }
              }
            }}
          >
            GO TO CO-ORDINATE
          </Components.BorderButton>
        </Components.SubContainer>
      ) : null}

      <Components.Container
        style={{
          display: isBottomNavOpen ? "flex" : "none",
          opacity: isNavbarFade ? "0" : "1"
        }}
      >
        <Components.Tab
          onClick={() => {
            if (selectedTab === 1) {
              setSelectedTab(0);
              setIsZoneTabOpen(false);
            } else {
              setSelectedTab(1);
              setIsZoneTabOpen(true);
            }
          }}
        >
          <img
            src={
              selectedTab === 1
                ? ZonesIconS
                : //@ts-ignore
                theme?.palette?.mode === "dark"
                ? ZonesIcon
                : ZonesIconD
            }
            style={{ color: "red" }}
          />
        </Components.Tab>
        <Components.Tab
          onClick={() => {
            if (selectedTab === 2) {
              setSelectedTab(0);
              setIsPropertyTabOpen(false);
            } else {
              setSelectedTab(2);
              setIsPropertyTabOpen(true);
            }
          }}
        >
          <img
            src={
              selectedTab === 2
                ? PropertyTypeIconS
                : //@ts-ignore
                theme?.palette?.mode === "dark"
                ? PropertyTypeIcon
                : PropertyTypeIconD
            }
          />
        </Components.Tab>
        <Components.Tab
          onClick={() => {
            if (selectedTab === 3) {
              setSelectedTab(0);
            } else {
              setSelectedTab(3);
            }
          }}
        >
          <img
            src={
              selectedTab === 3
                ? goToCoordinates
                : //@ts-ignore
                theme?.palette?.mode === "dark"
                ? goToCoordinatesLightMode
                : goToCoordinatesDarkMode
            }
            style={{ color: "white" }}
          />
        </Components.Tab>
        <Components.Tab
          onClick={() => {
            if (selectedTab === 4) {
              setSelectedTab(0);
              dispatch(setPropertyType(null));
              dispatch(setZoneType(null));
              dispatch(setSelectablePlots([]));
            } else {
              setSelectedTab(4);
              dispatch(setPropertyType("all"));
              dispatch(setZoneType(null));
            }
          }}
        >
          <img
            src={
              selectedTab === 4
                ? SelectionIconS
                : //@ts-ignore
                theme?.palette?.mode === "dark"
                ? SelectionIcon
                : SelectionIconD
            }
          />
        </Components.Tab>
        <Components.Tab
          onClick={() => {
            setSelectedTab(5);
            switchTheme();
          }}
        >
          <img
            src={
              selectedTab === 5
                ? DarkModeIconS
                : //@ts-ignore
                theme?.palette?.mode === "dark"
                ? DarkModeIcon
                : DarkModeIconD
            }
          />
        </Components.Tab>
      </Components.Container>
    </div>
  );
};

export default MobileOptionsBar;
