type ActionType = {
  type?: string;
  payload?: any;
};

type Box = {
  top: number;
  left: number;
};

type StateType = {
  selectedPlot: Box | null;
  selectablePlots: Box[] | [];
  selectedPropertyType: string | null;
  selectedZoneType: string | null;
  zoomToElement: boolean;
  boughtPlots: object | null;
};

const initState: StateType = {
  selectedPlot: null,
  selectablePlots: [],
  selectedPropertyType: null,
  selectedZoneType: null,
  zoomToElement: false,
  boughtPlots: null,
};

const plotReducer = (
  state: StateType = initState,
  action: ActionType
): StateType => {
  switch (action.type) {
    case "SET_BOUGHT_PLOTS": {
      return {
        ...state,
        boughtPlots: action.payload,
      };
    }

    case "SET_SELECTED_PLOT": {
      return {
        ...state,
        selectedPlot: action.payload,
      };
    }
    case "SET_SELECTABLE_PLOTS": {
      return {
        ...state,
        selectablePlots: action.payload,
      };
    }
    case "SET_PROPERTY_TYPE": {
      return {
        ...state,
        selectedPropertyType: action.payload,
      };
    }
    case "SET_ZONE_TYPE": {
      return {
        ...state,
        selectedZoneType: action.payload,
      };
    }
    case "SET_ZOOM_TO_ELEMENT": {
      return {
        ...state,
        zoomToElement: action.payload,
      };
    }
  }
  return state;
};

export default plotReducer;
