/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import { FC, useEffect, useState, useRef } from "react";
import { alpha } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import allLands from "../Categories/allPlot.json";
import comLand from "../Categories/comPlot.json";
import resLand from "../Categories/resPlot.json";
import redLand from "../Zones/redPlots.json";
import yellowLand from "../Zones/yellowPlots.json";
import greenLand from "../Zones/greenPlots.json";
import blueLand from "../Zones/bluePlots.json";
import redComLand from "../Zones/redComPlots.json";
import yellowComLand from "../Zones/yellowComPlots.json";
import greenComLand from "../Zones/greenComPlots.json";
import blueComLand from "../Zones/blueComPlots.json";
import redResLand from "../Zones/redResPlots.json";
import yellowResLand from "../Zones/yellowResPlots.json";
import greenResLand from "../Zones/greenResPlots.json";
import blueResLand from "../Zones/blueResPlots.json";
import ComPlotsAxis from "../Categories/comPlotAxis.json";
import RedPlotsAxis from "../Zones/redPlotsAxis.json";
import BluePlotsAxis from "../Zones/bluePlotsAxis.json";
import GreenPlotsAxis from "../Zones/greenPlotsAxis.json";
import ZoomInLogo from "../../../../assets/zoomIn.png";
import ZoomOutLogo from "../../../../assets/zoomOut.png";
import ZoomInLogoDark from "../../../../assets/zoomInDark.png";
import ZoomOutLogoDark from "../../../../assets/zoomOutDark.png";
import ResetLogo from "../../../../assets/reset.png";
import ResetLogoDark from "../../../../assets/resetDark.png";
import AmenitiesIcon from "../../../../assets/amenitiesIcon.svg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Amenities from "../Amenities/objects.json";
import AmenitiesName from "../Amenities/names.json";
import AmenitiesDescription from "../Amenities/descriptions.json";
import AmenitiesImage from "../Amenities/images.json";
import Tooltip from "@mui/material/Tooltip";
import CustomToolTipAmenities from "../CustomToolTipAmenities/index";
import CustomToolTipPlot from "../CustomToolTipPlot/index";
import Loading from "../LoadingMap";
import { useDispatch, useSelector } from "react-redux";
import { setRightSideBarStatus } from "../../../../redux/actions/modalActions";
import {
  setSelectedPlot,
  setSelectablePlots,
  setZoomToElement
} from "../../../../redux/actions/plotActions";
import ProfileIconL from "../../../../assets/profileLight.png";
import ProfileIconD from "../../../../assets/profileDark.png";
import { useThemeSwitch } from "../../../../hooks/switchTheme";

const ZoomButton = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "60px",
  height: "60px",
  margin: "3px",
  borderRadius: "10px",
  lineHeight: "0px",
  cursor: "pointer",
  boxShadow: "0px 0px 5px" + theme.palette.primary.main
}));

const BOX_SIZE = 240;

const DesktopMap: FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [imageSize, setImageSize] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [hoverDetails, setHoverDetails] = useState({
    top: 0,
    left: 0,
    height: 0,
    width: 0
  });
  const [boughtPlotsKeys, setBoughtPlotsKeys] = useState([]);

  const theme = useTheme();
  const [mode] = useThemeSwitch();
  const dispatch = useDispatch();

  const selectedPlot = useSelector((state: any) => {
    return state.plotReducer.selectedPlot;
  });

  const selectablePlots = useSelector((state: any) => {
    return state.plotReducer.selectablePlots;
  });

  const zoneType = useSelector((state: any) => {
    return state.plotReducer.selectedZoneType;
  });

  const propertyType = useSelector((state: any) => {
    return state.plotReducer.selectedPropertyType;
  });

  const isZoomToElement = useSelector((state: any) => {
    return state.plotReducer.zoomToElement;
  });

  const boughtPlots = useSelector((state: any) => {
    return state.plotReducer.boughtPlots;
  });

  const imgEl = useRef<HTMLImageElement>(null);

  const onImageLoaded = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  };

  const handleZoomToElement = (zoomToElement) => {
    let element =
      selectedPlot?.left.toString() + "," + selectedPlot?.top.toString();
    dispatch(setZoomToElement(false));
    setTimeout(() => {
      let element =
        selectedPlot?.left.toString() + "," + selectedPlot?.top.toString();
      zoomToElement(document.getElementById(element), 3);
    }, 300);
  };

  useEffect(() => {
    if (boughtPlots) {
      setBoughtPlotsKeys(Object.keys(boughtPlots));
    }
  }, [boughtPlots]);

  useEffect(() => {
    const imgElCurrent = imgEl.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener("load", onImageLoaded);
      return () => imgElCurrent.removeEventListener("load", onImageLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgEl]);

  useEffect(() => {
    if (propertyType === "showLand") {
      return;
    }

    if (propertyType !== "myLand") {
      dispatch(setSelectedPlot(null));
    }

    if (zoneType === "red") {
      if (propertyType === "com") {
        dispatch(setSelectablePlots(redComLand));
      } else if (propertyType === "res") {
        dispatch(setSelectablePlots(redResLand));
      } else {
        dispatch(setSelectablePlots(redLand));
      }
    } else if (zoneType === "yellow") {
      if (propertyType === "com") {
        dispatch(setSelectablePlots(yellowComLand));
      } else if (propertyType === "res") {
        dispatch(setSelectablePlots(yellowResLand));
      } else {
        dispatch(setSelectablePlots(yellowLand));
      }
    } else if (zoneType === "green") {
      if (propertyType === "com") {
        dispatch(setSelectablePlots(greenComLand));
      } else if (propertyType === "res") {
        dispatch(setSelectablePlots(greenResLand));
      } else {
        dispatch(setSelectablePlots(greenLand));
      }
    } else if (zoneType === "blue") {
      if (propertyType === "com") {
        dispatch(setSelectablePlots(blueComLand));
      } else if (propertyType === "res") {
        dispatch(setSelectablePlots(blueResLand));
      } else {
        dispatch(setSelectablePlots(blueLand));
      }
    } else if (propertyType === "com") {
      dispatch(setSelectablePlots(comLand));
    } else if (propertyType === "res") {
      dispatch(setSelectablePlots(resLand));
    } else if (propertyType === "all") {
      dispatch(setSelectablePlots(allLands));
    } else if (propertyType === "myLand") {
    } else {
      dispatch(setSelectablePlots([]));
    }
  }, [zoneType, propertyType]);

  useEffect(() => {
    const handleResize = (e) => {
      // dispatch(setPropertyType(null));
      // dispatch(setZoneType(null));
      setImageSize(window.innerWidth - 300);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setImageSize(window.innerWidth - 300);
  }, []);

  return (
    <>
      {!loaded ? <Loading /> : null}
      <TransformWrapper
        initialScale={1}
        limitToBounds={true}
        onZoomStart={() => {
          setIsScrolling(true);
        }}
        onZoomStop={() => {
          setIsScrolling(false);
        }}
        onInit={(e) => {
          e.centerView();
        }}
      >
        {({
          zoomIn,
          zoomOut,
          resetTransform,
          centerView,
          setTransform,
          zoomToElement,
          ...rest
        }) => (
          <>
            <TransformComponent
              wrapperStyle={{
                backgroundColor: theme?.palette?.primary?.main,
                width: "calc(100vw - 300px)",
                height: "100vh"
              }}
              contentStyle={{
                cursor: "grab"
              }}
            >
              <img
                ref={imgEl}
                src={
                  "https://d2btao7ncfyde3.cloudfront.net/Map%20Building%20Images/map-update2.jpg"
                }
                // src={
                //   "https://zord-storage-bucket.s3.ap-south-1.amazonaws.com/Map+Building+Images/map-update_3%5B186%5D.jpg"
                // }
                id="main-map"
                alt="Exarta Map"
                style={{
                  width: "calc(100vw - 300px)",
                  height: "calc(100vw - 300px)"
                }}
              />

              {selectablePlots.length > 0 ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#000",
                    opacity: "0.5",
                    position: "absolute",
                    left: "0",
                    top: "0"
                  }}
                />
              ) : null}

              {
                //selectable plots
              }

              {!isScrolling
                ? selectablePlots.map((box, index) => {
                    return (
                      <div
                        id={box.left + "," + box.top}
                        key={index}
                        style={{
                          position: "absolute",
                          zIndex: "1000",
                          top: box.top * (imageSize / BOX_SIZE),
                          left: box.left * (imageSize / BOX_SIZE),
                          height: imageSize / BOX_SIZE,
                          width: imageSize / BOX_SIZE,
                          cursor: "pointer",
                          backgroundImage: boughtPlotsKeys.includes(
                            `${box.left},${box.top}`
                          )
                            ? `url(${
                                boughtPlots[box.left + "," + box.top]
                                  ?.userDetails?.image
                              })`
                            : "none",
                          backgroundSize: imageSize / BOX_SIZE,
                          // backgroundPosition: "center",
                          padding: "1px",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: alpha(
                            zoneType === "red"
                              ? "#CA3C25"
                              : zoneType === "green"
                              ? "#519872"
                              : zoneType === "yellow"
                              ? "#F9DC5C"
                              : zoneType === "blue"
                              ? "#134074"
                              : propertyType === "com"
                              ? "#ffffff"
                              : propertyType === "res"
                              ? "#ffffff"
                              : theme.palette.primary.dark,
                            0.8
                          )
                        }}
                        onMouseOver={(e) => {
                          setHoverDetails({
                            top: box.top,
                            left: box.left,
                            height: imageSize / BOX_SIZE,
                            width: imageSize / BOX_SIZE
                          });
                        }}
                      ></div>
                    );
                  })
                : null}

              {
                //selected plot
              }

              {!isScrolling && selectedPlot != null ? (
                <div
                  className="box-selected"
                  style={{
                    position: "absolute",
                    top: selectedPlot.top * (imageSize / BOX_SIZE),
                    left: selectedPlot.left * (imageSize / BOX_SIZE),
                    height: imageSize / BOX_SIZE,
                    width: imageSize / BOX_SIZE,
                    backgroundColor: "black",
                    zIndex: "1000"
                  }}
                ></div>
              ) : null}

              {
                //amenities boxes
              }

              {!isScrolling
                ? Amenities.map((box, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={
                          <CustomToolTipAmenities
                            heading={AmenitiesName[index]}
                            description={AmenitiesDescription[index]}
                            image={AmenitiesImage[index]}
                          />
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "primary.main",
                              borderRadius: "9px",
                              "& .MuiTooltip-arrow": {
                                color: "common.black"
                              },
                              boxShadow:
                                "0px 0px 10px " + theme.palette.primary.main
                            }
                          }
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top:
                              box.top * (imageSize / BOX_SIZE) -
                              imageSize / BOX_SIZE,
                            left:
                              box.left * (imageSize / BOX_SIZE) -
                              imageSize / BOX_SIZE,
                            height: (imageSize / BOX_SIZE) * 2,
                            width: (imageSize / BOX_SIZE) * 2,
                            borderRadius: (imageSize / BOX_SIZE) * 3,
                            cursor: "pointer",
                            // backgroundColor: "#ff0084",
                            // border: "solid 1px #000000",
                            backgroundImage: `url(${AmenitiesIcon})`
                          }}
                        />
                      </Tooltip>
                    );
                  })
                : null}

              {selectablePlots.length > 0 &&
              hoverDetails.height > 0 &&
              !isScrolling ? (
                <Tooltip
                  title={
                    <CustomToolTipPlot
                      xCoordinate={hoverDetails.left.toString()}
                      yCoordinate={hoverDetails.top.toString()}
                      propertyType={
                        ComPlotsAxis.includes(
                          hoverDetails.left + "," + hoverDetails.top
                        )
                          ? "Commercial"
                          : "Residential"
                      }
                      zone={
                        RedPlotsAxis.includes(
                          hoverDetails.left + "," + hoverDetails.top
                        )
                          ? "red"
                          : GreenPlotsAxis.includes(
                              hoverDetails.left + "," + hoverDetails.top
                            )
                          ? "green"
                          : BluePlotsAxis.includes(
                              hoverDetails.left + "," + hoverDetails.top
                            )
                          ? "blue"
                          : "yellow"
                      }
                    />
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: "fit-content",
                        bgcolor: "primary.main",
                        borderRadius: "15px",
                        padding: "0px",

                        "& .MuiTooltip-arrow": {
                          height: "0px"
                        },

                        boxShadow: "0px 0px 10px " + theme.palette.primary.main
                      }
                    }
                  }}
                  PopperProps={{
                    sx: {
                      margin: "0px !important",
                      padding: "0px",

                      "& :first-child": {
                        margin: "0px !important"
                      }
                    }
                  }}
                >
                  <div
                    id={hoverDetails.left + "," + hoverDetails.top}
                    style={{
                      position: "absolute",
                      zIndex: "1000",
                      top: hoverDetails.top * (imageSize / BOX_SIZE),
                      left: hoverDetails.left * (imageSize / BOX_SIZE),
                      height: imageSize / BOX_SIZE,
                      width: imageSize / BOX_SIZE,
                      cursor: "pointer",
                      backgroundColor: "black",
                      opacity: "0.5"
                    }}
                    onClick={(e) => {
                      let temp = e.target.id.split(",");
                      dispatch(
                        setSelectedPlot({ left: +temp[0], top: +temp[1] })
                      );
                      dispatch(setRightSideBarStatus(true));
                    }}
                    // onMouseLeave={(e) => {
                    //   setHoverDetails({
                    //     top: 0,
                    //     left: 0,
                    //     height: 0,
                    //     width: 0,
                    //   });
                    // }}
                  ></div>
                </Tooltip>
              ) : null}
            </TransformComponent>

            <div
              style={{
                position: "fixed",
                left: 320,
                bottom: 20,
                display: "flex",
                flexDirection: "row",
                backgroundColor: "transparent",
                alignItems: "center"
              }}
            >
              {isZoomToElement && loaded
                ? handleZoomToElement(zoomToElement)
                : null}

              <ZoomButton
                onClick={() => {
                  zoomIn();
                }}
              >
                <img
                  style={{ height: "40px", width: "40px", userSelect: "none" }}
                  src={
                    theme?.palette?.mode === "light"
                      ? ZoomInLogoDark
                      : ZoomInLogo
                  }
                  alt="zoom in"
                />
              </ZoomButton>
              <ZoomButton
                onClick={() => {
                  zoomOut();
                }}
              >
                <img
                  style={{ height: "40px", width: "40px", userSelect: "none" }}
                  src={
                    theme?.palette?.mode === "light"
                      ? ZoomOutLogoDark
                      : ZoomOutLogo
                  }
                  alt="zoom out"
                />
              </ZoomButton>
              <ZoomButton
                onClick={() => {
                  centerView(1);
                }}
              >
                <img
                  style={{ height: "30px", width: "30px", userSelect: "none" }}
                  src={
                    theme?.palette?.mode === "light" ? ResetLogoDark : ResetLogo
                  }
                  alt="zoom out"
                />
              </ZoomButton>
            </div>
          </>
        )}
      </TransformWrapper>
    </>
  );
};

export default DesktopMap;
