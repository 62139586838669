import { FC, useState } from "react";
import { Checkbox, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";

import { isValidEmail } from "../../../../utils";
import {
  ErrorText,
  FormHeading,
  FormSubHeading,
  Input,
  ParentInput,
} from "../../index";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setLoadingModalStatus,
} from "../../../../redux/actions/modalActions";

const FormContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const RememberForgetDiv = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "20px",
  cursor:"pointer",

  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const RememberDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const FormSubText = styled("p")(({ theme }) => ({
  color: "#665a68",
  fontSize: "12px",
  fontWeight: "600",
}));

const LoginButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{
  disabled: boolean;
}>(({ theme, disabled }) => ({
  height: "50px",
  width: "50%",
  borderRadius: "30px",
  background: theme.palette.primary.dark,
  color: theme.palette.text.primary,
  margin: "25px auto",
  padding: "0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  fontWeight: "600",
  cursor: disabled ? "not-allowed" : "pointer",
  font: "normal normal normal Montserrat",
  letterSpacing: "3.43px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.paper,
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
    padding: "0px 15px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "85%",
    fontSize: "16px",
  },
}));

const SignUpForm: FC<{ setPage: (pageNumber: number) => void }> = ({
  setPage,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailStatus, setEmailStatus] = useState(true);
  const [passwordStatus, setPasswordStatus] = useState(true);
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);

  const dispatch = useDispatch();

  const firebase = useSelector((state: any) => {
    return state.authReducer.firebaseObj;
  });

  const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidEmail(event.target.value)) {
      setEmailStatus(true);
    } else {
      setEmailStatus(false);
    }
  };

  function SignUpWithEmail() {
    if (!checkBoxStatus) {
      dispatch(
        setAlertModalMsg({
          text: "Check the box for Terms & Conditions.",
          buttonText: "OK",
          onPress: () => dispatch(setAlertModalStatus(false)),
          heading: 'Alert',
        })
      );
      dispatch(setCheckoutModalStatus(false));
      dispatch(setAlertModalStatus(true));
      return;
    }

    if (firebase) {
      dispatch(setLoadingModalStatus(true));
      const auth = getAuth(firebase);
      createUserWithEmailAndPassword(auth, email, password)
        .then(async () => {
          const user = await auth?.currentUser;
          if (user) {
            await sendEmailVerification(user);
            dispatch(
              setAlertModalMsg({
                text: "Check your inbox for verification.",
                buttonText: "OK",
                onPress: () => dispatch(setAlertModalStatus(false)),
                heading: 'Alert',
              })
            );
            dispatch(setCheckoutModalStatus(false));
            dispatch(setAlertModalStatus(true));
            setPage(0);
          }
          dispatch(setLoadingModalStatus(false));
        })
        .catch((err) => {
          dispatch(setLoadingModalStatus(false));
          dispatch(
            setAlertModalMsg({
              text: err.message,
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: 'Alert',
            })
          );
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
          console.log(err);
        });
    }
  }

  return (
    <>
      <FormContainer>
        <FormHeading>Sign Up</FormHeading>
        <FormSubHeading onClick={() => setPage(0)}>
          Already have an account?
          <span style={{ color: "#bc679c" }}> Login</span>{" "}
        </FormSubHeading>
        <ParentInput>
          <Input
            placeholder="Email ID"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e);
            }}
          />
          <ErrorText>
            {!emailStatus &&
              email.length > 0 &&
              "Please enter a valid email address"}
          </ErrorText>
        </ParentInput>
        <ParentInput>
          <Input
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              if (e.target.value.length >= 8) {
                setPasswordStatus(true);
              } else setPasswordStatus(false);
            }}
            type="password"
          />
          <ErrorText>
            {!passwordStatus &&
              password.length > 0 &&
              "Minimum length of password should be 8"}
          </ErrorText>
        </ParentInput>
        <RememberForgetDiv>
          <RememberDiv>
            <Checkbox
              sx={{ color: "#bc679c", "&.Mui-checked": { color: "#bc679c" } }}
              onChange={() => {
                setCheckBoxStatus(!checkBoxStatus);
              }}
            />
            <FormSubText>
              I agree to{" "}
              <span style={{ color: "#BC679C" , cursor:"pointer" }} onClick={()=>{window.open('https://exarta.com/terms-and-conditions/')}}>Platform Terms</span> of
              Services and{" "}
              <span style={{ color: "#BC679C" , cursor:"pointer"}} onClick={()=>{window.open('https://exarta.com/terms-and-conditions/')}}>Privacy Policy</span>{" "}
            </FormSubText>
          </RememberDiv>
        </RememberForgetDiv>

        <LoginButton
          disabled={
            emailStatus && email.length > 0 && password.length > 0
              ? false
              : true
          }
          onClick={() => {
            if (emailStatus && email.length > 0 && password.length > 0) {
              SignUpWithEmail();
            }
          }}
        >
          CREATE ACCOUNT
        </LoginButton>
      </FormContainer>
    </>
  );
};

export default SignUpForm;
