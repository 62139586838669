import { styled } from "@mui/material/styles";
import { FC } from "react";
import fbicon from "../../../../assets/fbicon.svg";
import GoogleIcon from "../../../../assets/gicon.svg";
import TwitterIcon from "../../../../assets/ticon.svg";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  TwitterAuthProvider,
  UserCredential,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAuthToken,
  clearAuth,
  clearUser,
  setUserFirebaseDetails,
} from "../../../../redux/actions/authActions/index";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setLoadingModalStatus,
} from "../../../../redux/actions/modalActions";
import { existsSync } from "fs";

const Container = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const SignInDiv = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const Hr = styled("hr")(({ theme }) => ({
  flex: "1",
  height: "1px",
  border: "0",
  borderTop: "1px solid " + theme.palette.text.secondary,
  width: "25%",
  opacity: "0.5",
}));

const Heading = styled("p")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "14px",
  fontWeight: "500",
  opacity: "0.5",
  margin: "0px 10px",
}));

const SocialMediaDiv = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "60%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "85%",
  },
}));

const Circle = styled("div")(({ theme }) => ({
  height: "60px",
  width: "60px",
  borderRadius: "30px",
  display: "flex",
  border: "1px solid" + theme.palette.primary.dark,
  alignItems: "center",
  justifyContent: "space-around",
  cursor: "pointer",
  margin: "0px 15px",
}));

const Rectangle = styled("div")(({ theme }) => ({
  borderRadius: "40px",
  padding:"5px 10px",
  display: "flex",
  border: "1px solid" + theme.palette.primary.dark,
  alignItems: "center",
  cursor: "pointer",
  ":hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));


const SocialMediaIcons = styled("img")(({ theme }) => ({
  height: "40px",
}));

const SocialLogin: FC = () => {
  const firebase = useSelector((state: any) => {
    return state.authReducer.firebaseObj;
  });
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const twitterProvider = new TwitterAuthProvider();
  const dispatch = useDispatch();

  function SignInWithGoogle() {
    if (firebase) {
      dispatch(setLoadingModalStatus(true));

      const auth = getAuth(firebase);
      signInWithPopup(auth, googleProvider)
        .then(async (res: UserCredential) => {
          // @ts-ignore
          const expiry = res.user?.stsTokenManager.expirationTime;
          const token = await auth?.currentUser?.getIdToken(true);
          if (token) {
            dispatch(setUserFirebaseDetails(res));
            dispatch(changeAuthToken(token, expiry));
          }
          dispatch(setLoadingModalStatus(false));
        })
        .catch((err) => {
          dispatch(setLoadingModalStatus(false));
          dispatch(
            setAlertModalMsg({
              text: err.message,
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: 'Alert',
            })
          );
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
          console.log(err);
        });
    }
  }

  function SignInWithFacebook() {
    if (firebase) {
      dispatch(setLoadingModalStatus(true));

      const auth = getAuth(firebase);
      signInWithPopup(auth, facebookProvider)
        .then(async (res) => {
          //@ts-ignore
          const expiry = res.user?.stsTokenManager.expirationTime;
          const token = await auth?.currentUser?.getIdToken(true);
          if (token) {
            dispatch(setUserFirebaseDetails(res));
            dispatch(changeAuthToken(token, expiry));
          }
          dispatch(setLoadingModalStatus(false));
        })
        .catch((err) => {
          dispatch(setLoadingModalStatus(false));
          dispatch(
            setAlertModalMsg({
              text: err.message,
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: 'Alert',
            })
          );
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
          console.log(err);
        });
    }
  }

  function SignInWithTwitter() {
    if (firebase) {
      dispatch(setLoadingModalStatus(true));

      const auth = getAuth(firebase);
      signInWithPopup(auth, twitterProvider)
        .then(async (res) => {
          //@ts-ignore
          const expiry = res.user?.stsTokenManager.expirationTime;
          const token = await auth?.currentUser?.getIdToken(true);
          if (token) {
            dispatch(setUserFirebaseDetails(res));
            dispatch(changeAuthToken(token, expiry));
          }
          dispatch(setLoadingModalStatus(false));

          setTimeout(() => {
            dispatch(clearAuth());
            dispatch(clearUser());
            dispatch(
              setAlertModalMsg({
                text: "Your session expired, please login in again.",
                buttonText: "OK",
                onPress: () => dispatch(setAlertModalStatus(false)),
                heading: 'Alert',
              })
            );
            dispatch(setCheckoutModalStatus(false));
            dispatch(setAlertModalStatus(true));
          }, expiry - Date.now());
        })
        .catch((err) => {
          dispatch(setLoadingModalStatus(false));
          dispatch(
            setAlertModalMsg({
              text: err.message,
              buttonText: "OK",
              onPress: () => dispatch(setAlertModalStatus(false)),
              heading: 'Alert',
            })
          );
          dispatch(setCheckoutModalStatus(false));
          dispatch(setAlertModalStatus(true));
          console.log(err);
        });
    }
  }

  return (
    <>
      <Container>
        <SignInDiv>
          <Hr />
          <Heading>Or Sign In Using</Heading>
          <Hr />
        </SignInDiv>

        <SocialMediaDiv>
          <Rectangle
            onClick={() => {
              SignInWithGoogle();
            }}
          >
            <SocialMediaIcons src={GoogleIcon} />
            <div>Sign in with Google</div>
          </Rectangle>
          {/* <Circle
            onClick={() => {
              SignInWithFacebook();
            }}
          >
            <SocialMediaIcons src={fbicon} />
          </Circle> */}
          {/* <Circle
            onClick={() => {
              SignInWithTwitter();
            }}
          >
            <SocialMediaIcons src={TwitterIcon} />
          </Circle> */}
        </SocialMediaDiv>
      </Container>
    </>
  );
};

export default SocialLogin;
