/* eslint-disable jsx-a11y/alt-text */
import { FC } from "react";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";

const Container = styled("div")(({ theme }) => ({
  padding: "9px",
}));

const Index: FC<{ heading: string; description: string; image:string }> = ({
  heading,
  description,
  image
}) => {
  const theme = useTheme();
  return (
    <Container>
      <h1
        style={{
          fontSize: "23px",
          //@ts-ignore
          color: theme?.palette?.text?.primary,
        }}
      >
        {heading}
      </h1>
      <span
        style={{
          fontSize: "13px",
          //@ts-ignore
          color: theme?.palette?.text?.primary,
        }}
      >
        {description}
      </span>
      <img
        style={{
          width: "100%",
          height: "150px",
          marginTop: "10px",
          borderRadius: "7px",
        }}
        src={image}
      ></img>
    </Container>
  );
};

export default Index;
