type ActionType = {
  type?: string;
  payload?: any;
};

type StateType = {
  authToken: string | null;
  expiryTime: number | null;
  firebaseObj: any;
};

const initState: StateType = {
  authToken: null,
  firebaseObj: null,
  expiryTime: null,
};

const authReducer = (
  state: StateType = initState,
  action: ActionType
): StateType => {
  switch (action.type) {
    case "SET_FIREBASE_OBJECT": {
      return {
        ...state,
        firebaseObj: action.payload,
      };
    }
    case "SET_AUTH_TOKEN": {
      return {
        ...state,
        authToken: action.payload.authToken,
        expiryTime: action.payload.expiryTime,
      };
    }
    case "CLEAR_AUTH": {
      return { ...state, authToken: null, expiryTime: null };
    }
  }
  return state;
};

export default authReducer;
