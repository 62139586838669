/* eslint-disable jsx-a11y/alt-text */
import { FC } from "react";
import { styled } from "@mui/material/styles";

const Container = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "calc(100vw - 300px)",
  backgroundColor: theme.palette.primary.main,
  position: "fixed",
  left: "300px",
  top: "0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "100",

  [theme.breakpoints.down("md")]: {
    width: "100vw",
    left: "0",
  },
}));

const Loader = () => {
  return (
    <div className="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const Loading: FC = () => {
  return (
    <Container>
      <Loader />
      Loading Map
    </Container>
  );
};

export default Loading;
