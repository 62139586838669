type ActionType = {
  type?: string;
  payload?: any;
};

type StateType = {
  checkoutModalStatus: boolean;
  rightSideBarStatus: boolean;
  alertModalStatus: boolean;
  alertModalData: {
    text: string;
    buttonText: string;
    onPress: () => void;
    heading: string;
  };
  loadingModalStatus: boolean;
};

const initState: StateType = {
  checkoutModalStatus: false,
  rightSideBarStatus: false,
  alertModalStatus: false,
  loadingModalStatus: false,
  alertModalData: {
    text: "Some Error Occurred, Try Again.",
    buttonText: "OK",
    onPress: () => (initState.alertModalStatus = false),
    heading: 'Alert',
  }
};

const modalReducer = (
  state: StateType = initState,
  action: ActionType
): StateType => {
  switch (action.type) {
    case "SET_CHECKOUT_MODAL_STATUS": {
      return {
        ...state,
        checkoutModalStatus: action.payload
      };
    }
    case "SET_RIGHT_SIDE_BAR_STATUS": {
      return {
        ...state,
        rightSideBarStatus: action.payload
      };
    }
    case "SET_ALERT_MODAL_STATUS": {
      return {
        ...state,
        alertModalStatus: action.payload
      };
    }
    case "SET_ALERT_MODAL_DATA": {
      return {
        ...state,
        alertModalData: { ...action.payload }
      };
    }
    case "SET_LOADING_MODAL_STATUS": {
      return {
        ...state,
        loadingModalStatus: action.payload
      };
    }
  }
  return state;
};

export default modalReducer;
