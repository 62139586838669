import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import ProfileButton from "../../../Home/components/ProfileButton";
import CartButton from "../../../Home/components/CartButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const Header = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "90px",
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  alignItems: "flex-start",
  position: "relative",
  top: "0px",
  left: "0px",

  [theme.breakpoints.down("md")]: {
    height: "65px",
  },
}));

const BackDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
  padding: "20px 30px",

  [theme.breakpoints.down("sm")]: {
    padding: "20px 10px",
  },
}));

const BackText = styled("h5")(({ theme }) => ({
  fontSize: "19px",
  lineHeight: "15px",
  textTransform: "uppercase",
  fontWeight: "500",
}));

const HeaderComponent: FC = () => {
  const navigate = useNavigate();

  return (
    <Header>
      <BackDiv onClick={() => navigate("/")}>
        <KeyboardArrowLeftIcon style={{ height: "30px", width: "30px" }} />
        <BackText>Back</BackText>
      </BackDiv>

      <CartButton />
      <ProfileButton />
    </Header>
  );
};

export default HeaderComponent;
