/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState, useRef } from "react";
import { alpha } from "@mui/material/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useTheme } from "@mui/material/styles";
import allLands from "../Categories/allPlot.json";
import comLand from "../Categories/comPlot.json";
import resLand from "../Categories/resPlot.json";
import redLand from "../Zones/redPlots.json";
import yellowLand from "../Zones/yellowPlots.json";
import greenLand from "../Zones/greenPlots.json";
import blueLand from "../Zones/bluePlots.json";
import redComLand from "../Zones/redComPlots.json";
import yellowComLand from "../Zones/yellowComPlots.json";
import greenComLand from "../Zones/greenComPlots.json";
import blueComLand from "../Zones/blueComPlots.json";
import redResLand from "../Zones/redResPlots.json";
import yellowResLand from "../Zones/yellowResPlots.json";
import greenResLand from "../Zones/greenResPlots.json";
import blueResLand from "../Zones/blueResPlots.json";
import Amenities from "../Amenities/objects.json";
import AmenitiesName from "../Amenities/names.json";
import AmenitiesIcon from "../../../../assets/amenitiesIcon.svg";
import Tooltip from "@mui/material/Tooltip";
import CustomToolTipAmenities from "../CustomToolTipAmenities/index";
import Loading from "../LoadingMap";
import { setRightSideBarStatus } from "../../../../redux/actions/modalActions";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedPlot,
  setSelectablePlots,
  setZoomToElement
} from "../../../../redux/actions/plotActions";
import ProfileIconL from "../../../../assets/profileLight.png";
import ProfileIconD from "../../../../assets/profileDark.png";
import { useThemeSwitch } from "../../../../hooks/switchTheme";

const BOX_SIZE = 240;

const MobileMap: FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [imageSize, setImageSize] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [boughtPlotsKeys, setBoughtPlotsKeys] = useState<string[]>([]);

  const theme = useTheme();
  const [mode] = useThemeSwitch();

  const imgEl = useRef<HTMLImageElement>(null);

  const dispatch = useDispatch();

  const selectedPlot = useSelector((state: any) => {
    return state.plotReducer.selectedPlot;
  });

  const selectablePlots = useSelector((state: any) => {
    return state.plotReducer.selectablePlots;
  });

  const zoneType = useSelector((state: any) => {
    return state.plotReducer.selectedZoneType;
  });

  const propertyType = useSelector((state: any) => {
    return state.plotReducer.selectedPropertyType;
  });

  const boughtPlots = useSelector((state: any) => {
    return state.plotReducer.boughtPlots;
  });

  const isZoomToElement = useSelector((state: any) => {
    return state.plotReducer.zoomToElement;
  });

  const onImageLoaded = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  };

  useEffect(() => {
    if (boughtPlots) {
      setBoughtPlotsKeys(Object.keys(boughtPlots));
    }
  }, [boughtPlots]);

  useEffect(() => {
    const imgElCurrent = imgEl.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener("load", onImageLoaded);
      return () => imgElCurrent.removeEventListener("load", onImageLoaded);
    }
  }, [imgEl]);

  useEffect(() => {
    if (propertyType !== "myLand") {
      dispatch(setSelectedPlot(null));
    }

    if (zoneType === "red") {
      if (propertyType === "com") {
        dispatch(setSelectablePlots(redComLand));
      } else if (propertyType === "res") {
        dispatch(setSelectablePlots(redResLand));
      } else {
        dispatch(setSelectablePlots(redLand));
      }
    } else if (zoneType === "yellow") {
      if (propertyType === "com") {
        dispatch(setSelectablePlots(yellowComLand));
      } else if (propertyType === "res") {
        dispatch(setSelectablePlots(yellowResLand));
      } else {
        dispatch(setSelectablePlots(yellowResLand));
      }
    } else if (zoneType === "green") {
      if (propertyType === "com") {
        dispatch(setSelectablePlots(greenComLand));
      } else if (propertyType === "res") {
        dispatch(setSelectablePlots(greenResLand));
      } else {
        dispatch(setSelectablePlots(greenLand));
      }
    } else if (zoneType === "blue") {
      if (propertyType === "com") {
        dispatch(setSelectablePlots(blueComLand));
      } else if (propertyType === "res") {
        dispatch(setSelectablePlots(blueResLand));
      } else {
        dispatch(setSelectablePlots(blueLand));
      }
    } else if (propertyType === "com") {
      dispatch(setSelectablePlots(comLand));
    } else if (propertyType === "res") {
      dispatch(setSelectablePlots(resLand));
    } else if (propertyType === "all") {
      dispatch(setSelectablePlots(allLands));
    } else if (propertyType === "myLand") {
    } else {
      dispatch(setSelectablePlots([]));
    }
  }, [zoneType, propertyType]);

  useEffect(() => {
    const handleResize = (e) => {
      setImageSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setImageSize(window.innerWidth);
  }, []);

  const handleZoomToElement = (zoomToElement) => {
    let element =
      selectedPlot?.left.toString() + "," + selectedPlot?.top.toString();
    dispatch(setZoomToElement(false));
    setTimeout(() => {
      let element =
        selectedPlot?.left.toString() + "," + selectedPlot?.top.toString();
      zoomToElement(document.getElementById(element), 3);
    }, 300);
    return null;
  };

  return (
    <>
      {!loaded ? <Loading /> : null}

      <TransformWrapper
        initialScale={1}
        limitToBounds={true}
        onZoomStart={() => {
          // setIsScrolling(true);
        }}
        onZoomStop={() => {
          // setIsScrolling(false);
        }}
        onInit={(e) => {
          e.centerView(2);
        }}
      >
        {({
          zoomIn,
          zoomOut,
          resetTransform,
          centerView,
          setTransform,
          zoomToElement,
          ...rest
        }) => (
          <>
            <TransformComponent
              wrapperStyle={{
                backgroundColor: theme?.palette?.primary?.main,
                width: "100vw",
                height: "100vh",
                paddingTop: "50px"
              }}
              contentStyle={{
                cursor: "grab"
              }}
            >
              <img
                ref={imgEl}
                src={
                  "https://d2btao7ncfyde3.cloudfront.net/Map%20Building%20Images/map-update2.jpg"
                }
                // src={
                //   "https://firebasestorage.googleapis.com/v0/b/insta-clone-305513.appspot.com/o/Final.jpg?alt=media&token=9cb9b132-05b3-4585-aa99-6b087429cb47"
                // }
                id="main-map"
                alt="Exarta Map"
                style={{
                  width: "100vw",
                  height: "100vw",
                  marginBottom: "150px"
                }}
              />

              {selectablePlots.length > 0 ? (
                <div
                  style={{
                    width: "100%",
                    height: "calc( 100% - 150px )",
                    backgroundColor: "#000",
                    opacity: "0.4",
                    position: "absolute",
                    left: "0",
                    top: "0"
                  }}
                />
              ) : null}

              {
                //selectable plots
              }

              {!isScrolling
                ? selectablePlots.map((box, index) => {
                    return (
                      <div
                        id={box.left + "," + box.top}
                        key={index}
                        style={{
                          position: "absolute",
                          zIndex: "1000",
                          top: box.top * (imageSize / BOX_SIZE),
                          left: box.left * (imageSize / BOX_SIZE),
                          height: imageSize / BOX_SIZE,
                          width: imageSize / BOX_SIZE,
                          cursor: "pointer",
                          backgroundImage: boughtPlotsKeys.includes(
                            `${box.left},${box.top}`
                          )
                            ? `url(${
                                mode === "light" ? ProfileIconL : ProfileIconD
                              })`
                            : "none",
                          backgroundSize: imageSize / BOX_SIZE,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: alpha(
                            zoneType === "red"
                              ? "#CA3C25"
                              : zoneType === "green"
                              ? "#519872"
                              : zoneType === "yellow"
                              ? "#F9DC5C"
                              : zoneType === "blue"
                              ? "#134074"
                              : propertyType === "com"
                              ? "#ffffff"
                              : propertyType === "res"
                              ? "#ffffff"
                              : theme.palette.primary.dark,
                            box.top % 2 == 0
                              ? box.left % 2 == 0
                                ? 0.7
                                : 1
                              : box.left % 2 == 0
                              ? 1
                              : 0.7
                          )
                        }}
                        onClick={(e) => {
                          //@ts-ignore
                          let temp = e.target.id.split(",");
                          dispatch(
                            setSelectedPlot({ left: +temp[0], top: +temp[1] })
                          );
                          dispatch(setRightSideBarStatus(true));
                        }}
                      >
                        {isZoomToElement && loaded
                          ? handleZoomToElement(zoomToElement)
                          : null}
                      </div>
                    );
                  })
                : null}
              {!isScrolling && selectedPlot != null ? (
                <div
                  className="box-selected"
                  style={{
                    position: "absolute",
                    top: selectedPlot.top * (imageSize / BOX_SIZE),
                    left: selectedPlot.left * (imageSize / BOX_SIZE),
                    height: imageSize / BOX_SIZE,
                    width: imageSize / BOX_SIZE,
                    backgroundColor: "black",
                    zIndex: "100000"
                  }}
                ></div>
              ) : null}

              {
                //amenities
              }

              {!isScrolling
                ? Amenities.map((box, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          position: "absolute",
                          top:
                            box.top * (imageSize / BOX_SIZE) -
                            imageSize / BOX_SIZE,
                          left:
                            box.left * (imageSize / BOX_SIZE) -
                            imageSize / BOX_SIZE,
                          height: (imageSize / BOX_SIZE) * 2,
                          width: (imageSize / BOX_SIZE) * 2,
                          borderRadius: (imageSize / BOX_SIZE) * 3,
                          cursor: "pointer",
                          backgroundImage: `url(${AmenitiesIcon})`
                        }}
                      />
                    );
                  })
                : null}
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </>
  );
};

export default MobileMap;
