// themeReducer-types
export const CHANGE_THEME = "CHANGE_THEME";

// authReducer-types
export const SET_FIREBASE_OBJECT = "SET_FIREBASE_OBJECT";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const CLEAR_AUTH = "CLEAR_AUTH";

// cartReducer-types
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";

// userReducer-types
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_USER_FIREBASE_DETAILS = "SET_USER_FIREBASE_DETAILS";
export const CLEAR_USER = "CLEAR_USER";

// modalReducer-types
export const SET_CHECKOUT_MODAL_STATUS = "SET_CHECKOUT_MODAL_STATUS";
export const SET_RIGHT_SIDE_BAR_STATUS = "SET_RIGHT_SIDE_BAR_STATUS";
export const SET_ALERT_MODAL_STATUS = "SET_ALERT_MODAL_STATUS";
export const SET_ALERT_MODAL_DATA = "SET_ALERT_MODAL_DATA";
export const SET_LOADING_MODAL_STATUS = "SET_LOADING_MODAL_STATUS";

// plotReducer-types
export const SET_SELECTED_PLOT = "SET_SELECTED_PLOT";
export const SET_SELECTABLE_PLOTS = "SET_SELECTABLE_PLOTS";
export const SET_PROPERTY_TYPE = "SET_PROPERTY_TYPE";
export const SET_ZONE_TYPE = "SET_ZONE_TYPE";
export const SET_ZOOM_TO_ELEMENT = "SET_ZOOM_TO_ELEMENT";
export const SET_BOUGHT_PLOTS = "SET_BOUGHT_PLOTS";
