import { UserCredential } from "firebase/auth";
import { SET_USER_DETAILS, CLEAR_USER, SET_USER_FIREBASE_DETAILS } from "../../types";

type ActionType = {
  type: string;
  payload: any;
};

type StateType = {
  uid: string;
  name: string;
  email: string;
  image: string;
  plot: string[];
  favouratePlots: string[];
  bio: string;
  walletAddress: string;
  twitterHandle: string;
  websiteUrl: string;
  user: UserCredential | {};
};

const initState: StateType = {
  uid: "",
  name: "",
  email: "",
  image: "",
  plot: [],
  favouratePlots: [],
  bio: "",
  walletAddress: "",
  twitterHandle: "",
  websiteUrl: "",
  user: {},
};

const userReducer = (
  state: StateType = initState,
  action: ActionType
): StateType => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_USER:
      return {
        ...state,
        uid: "",
        name: "",
        email: "",
        image: "",
        plot: [],
        favouratePlots: [],
        bio: "",
        walletAddress: "",
        twitterHandle: "",
        websiteUrl: "",
      };
    case SET_USER_FIREBASE_DETAILS:
      return {
        ...state,
        user: action.payload,
      }
  }
  return state;
};

export default userReducer;
