/* eslint-disable jsx-a11y/alt-text */
import { FC } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LandLogo } from "../../../../assets/index";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlertModalMsg,
  setAlertModalStatus,
  setCheckoutModalStatus,
  setRightSideBarStatus
} from "../../../../redux/actions/modalActions";
import { addToCart } from "../../../../redux/actions/cartActions";
import * as Components from "./components";
import { formatCurrency, getPlotPrice } from "../../../../utils";

const CheckoutModal: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedPlot = useSelector((state: any) => {
    return state.plotReducer.selectedPlot;
  });

  const authToken = useSelector((state: any) => {
    return state.authReducer.authToken;
  });
  const handleAddToCart = () => {
    if (authToken) {
      dispatch(addToCart(selectedPlot.left + "," + selectedPlot.top));
      dispatch(setCheckoutModalStatus(false));
      dispatch(setRightSideBarStatus(false));
      const coordinates = selectedPlot.left + "," + selectedPlot.top;
      dispatch(
        setAlertModalMsg({
          text: "Item Added to Cart.",
          buttonText: "OK",
          onPress: () => dispatch(setAlertModalStatus(false)),
          heading: coordinates
        })
      );
      dispatch(setAlertModalStatus(true));
    } else {
      dispatch(
        setAlertModalMsg({
          text: "You need to login first.",
          buttonText: "Login",
          onPress: () => {
            dispatch(setAlertModalStatus(false));
            navigate("/login");
          },
          heading: "Alert"
        })
      );
      dispatch(setCheckoutModalStatus(false));
      dispatch(setAlertModalStatus(true));
    }
  };

  return (
    <Components.MainContainer>
      <Components.CloseButton
        onClick={() => {
          dispatch(setCheckoutModalStatus(false));
        }}
      >
        X
      </Components.CloseButton>
      <Components.Heading>Property Details</Components.Heading>
      <Components.HorizontalDivider opacity={0.5} />
      <div
        style={{
          display: "flex",
          width: isMobile ? "90%" : "80%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "30px",
          marginBottom: "40px",
          flexWrap: "wrap"
        }}
      >
        <Components.SubHeading>Item</Components.SubHeading>
        <Components.SubHeading style={{ marginLeft: "auto" }}>
          Total
        </Components.SubHeading>
        <Components.HorizontalDivider opacity={0.3} />
      </div>
      <div
        style={{
          overflow: "hidden",
          maxHeight: "400px"
        }}
      >
        <Components.ItemBox isMobile>
          <img src={LandLogo} style={{ height: "60px", marginRight: "25px" }} />
          <Components.SubHeading>
            Land ({selectedPlot?.left + "," + selectedPlot?.top})
          </Components.SubHeading>

          <div style={{ marginLeft: "auto" }}>
            <Components.SubHeading style={{ fontWeight: "500" }}>
              {formatCurrency(
                getPlotPrice(selectedPlot?.left + "," + selectedPlot?.top)
              )}{" "}
              $
            </Components.SubHeading>
          </div>
        </Components.ItemBox>
      </div>
      <Components.ButtonBoxStyled>
        <Components.BorderButton
          style={{ marginRight: "15px" }}
          onClick={() => {
            handleAddToCart();
          }}
        >
          Add to Cart
        </Components.BorderButton>
        {/* <Components.BorderButton
          onClick={() => {
            dispatch(setCheckoutModalStatus(false));
            navigate("/checkout");
          }}
        >
          Checkout
        </Components.BorderButton> */}
      </Components.ButtonBoxStyled>
    </Components.MainContainer>
  );
};

export default CheckoutModal;
